'use strict'
import {
    RECEIVE_INSTALLATION,
    RECEIVE_INSTALLATIONS,
    RESET_INSTALLATION,
    RECEIVE_MAP_SITUATIONS,
    RECEIVE_ALL_LINKED_STATIONS,
    RECEIVE_MAP_SITUATION_STATS,
    RECEIVE_MAP_SITUATIONS_RESULTS,
} from '../constants/InstallationsConstants'
import DtoInstallation from '../dto/DtoInstallation'
import DtoInstallationGeo from '../dto/DtoInstallationGeo'
import DtoAssociatedStation from '../dto/DtoAssociatedStation'
import { FULL_RESET } from '../../../../offline/constants/HomeConstants'
import DtoMapSituationResult from '../dto/DtoMapSituationResult'
import DtoMapSituation from '../dto/DtoMapSituation'
import { RESET_EXPLOIT } from '../../../exploitation/constants/ExploitationConstants'

export const store = {
    allLinkedStations: [],
    installation: {},
    installations: [],
    mapSituations: [],
    mapSituationStats: [],
    mapSituationResults: [],
}

export function InstallationsReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_INSTALLATION:
            return {
                ...state,
                installation: new DtoInstallation(action.installation),
            }
        case RECEIVE_INSTALLATIONS:
            return {
                ...state,
                installations: action.installations.map((i) => new DtoInstallationGeo(i)),
            }
        case RESET_INSTALLATION:
            return {
                ...state,
                installation: {},
            }
        case RECEIVE_MAP_SITUATIONS:
            return {
                ...state,
                mapSituations: action.data.map(s => new DtoMapSituation(s)),
            }
        case RECEIVE_MAP_SITUATION_STATS:
            return {
                ...state,
                mapSituationStats: action.data,
            }
        case RECEIVE_MAP_SITUATIONS_RESULTS:
            return {
                ...state,
                mapSituationResults: action.data.map(s => new DtoMapSituationResult(s)),
            }
        case RECEIVE_ALL_LINKED_STATIONS:
            return {
                ...state,
                allLinkedStations: action.data.map(s => new DtoAssociatedStation(s)),
            }
        case RESET_EXPLOIT:
            return {
                ...store,
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
