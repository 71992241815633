/* eslint-disable max-nested-callbacks */
import { Grid2, useTheme } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { darkRed, mainRed, textColor } from '../components/styled/theme'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import LoadingCard from '../components/cards/LoadingCard'
import Table from '../components/Table'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'
import moment from 'moment'
import { compact, omit, orderBy, round, template, uniqWith } from 'lodash'
import { CHRONICLES_CONSTANTS, RESTRICTION_COLOR, RESTRICTION_RESOURCE_TYPE } from '../agri/constants/AgriConstants'
import { orderPointsList } from '../../../utils/ObjectUtils'
import { getDelaySinceLastEntry, getIPSIconDesktop, getIPSLabel, getRestrictionIconV2, getSitusAndIdsVarious, getStateLabel } from '../../../utils/AgriUtils'
import { convertToRGB } from '../../../utils/ColorUtil'
import { MainButton, OrangeButton, RedButton } from '../components/styled/buttons'
import { push } from '@lagunovsky/redux-react-router'
import { Check, Error, HourglassTop, QuestionMark, Today } from '@mui/icons-material'
import ModalIndexEntry from '../components/ModalIndexEntry'
import { formatMilliers, getI18nTitleDataLength } from '../../../utils/StringUtil'
import { POINT_STATUS_OBJ, STATION_TYPE_CONSTANT } from '../referencials/installations/constants/InstallationsConstants'
import useProgressDispatch from '../../../utils/customHooks/useProgressDispatch'
import ExploitationAction from '../exploitation/actions/ExploitationAction'

const PointsListAppDesktop = () => {
    const [pointsLoaded, setPointsLoaded] = useState(false)
    const [situationResultLoaded, setSituationResultLoaded] = useState(false)
    const [openModalNewIndex, setOpenModalNewIndex] = useState(false)
    const [indexedPoint, setIndexedPoint] = useState({})

    const {
        exploitation,
        installations,
        variousMateriels,
        variousMatSituations,
        droughtSectorsRestrictions,
        managementUnitsRestrictions,
        waterTurnsRestrictions,
        exploitationVolumes,
        mapSituations,
        mapSituationStats,
        mapSituationResults,
        allLinkedStations,
        citiesIndex,
    } = useSelector(store => ({
        exploitation: store.ExploitationReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        variousMateriels: store.MaterielReducer.variousMateriels,
        variousMatSituations: store.MaterielReducer.variousMatSituations,
        droughtSectorsRestrictions: store.ReferencialReducer.droughtSectorsRestrictions,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: store.ReferencialReducer.waterTurnsRestrictions,
        exploitationVolumes: store.ExploitationReducer.exploitationVolumes,
        mapSituations: store.InstallationsReducer.mapSituations,
        mapSituationStats: store.InstallationsReducer.mapSituationStats,
        mapSituationResults: store.InstallationsReducer.mapSituationResults,
        allLinkedStations: store.InstallationsReducer.allLinkedStations,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', s => s?.split(',')?.map(id => parseInt(id)))
    const typeRestriction = useApplicationSetting('agriTypeRestriction') ?? RESTRICTION_RESOURCE_TYPE.MANAGEMENT_UNITS

    const dispatch = useDispatch()

    const theme = useTheme()

    const getWaterTurnsRestriction = (point) => {
        if (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS) {
            const restriction = droughtSectorsRestrictions.find((dsR) => dsR.idSector === point.droughtSector && point.sampleType === dsR.resourceType) || { idRestriction: -1 }
            return waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
        }
        const restriction = managementUnitsRestrictions.find((ugeR) => (ugeR.managementCode === point.subManagementCode || ugeR.managementCode === point.managementCode) && point.sampleType === ugeR.resourceType) || { idRestriction: -1 }
        return waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
    }

    const nbVHAAlert = useMemo(() => installations.reduce((nbAlert, instal) => {
        const restriction = getWaterTurnsRestriction(instal)
        if (restriction?.percentRestriction && instal.annualWeeklyVolume) {
            return nbAlert + 1
        }
        return nbAlert
    }, 0), [installations, droughtSectorsRestrictions, managementUnitsRestrictions, waterTurnsRestrictions])

    const navigate = (path) => {
        dispatch(push(path))
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        dispatch(ReferencialAction.fetchManagementUnitsRestrictions())
        dispatch(ReferencialAction.fetchDroughtSectorsRestrictions())
        if (!exploitation?.idExploitation) {
            dispatch(ExploitationAction.fetchExploitation())
        }
    }, [])

    useEffect(() => {
        if (exploitation?.idExploitation) {
            dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation)).then(() => {
                dispatch(InstallationsAction.fetchMapSituations('piezometry')).then(() => setPointsLoaded(true))
            })
        }
    }, [exploitation])

    const map = useMemo(() => mapSituations.find((m) => m.default), [mapSituations])

    const { isLoaded: situationsLoaded } = useProgressDispatch(() => {
        const promiseMapSituation = map ? [
            dispatch(InstallationsAction.fetchMapSituationsStationStats('piezometry', map?.id))
        ] : []
        return ([
            ...promiseMapSituation,
            dispatch(InstallationsAction.fetchAllLinkedStations(exploitation?.link_samplings?.map((l) => l.idInstallation), STATION_TYPE_CONSTANT.installation)),
        ])
    }, [pointsLoaded])

    useEffect(() => {
        if (mapSituationStats?.length && map) {
            dispatch(InstallationsAction.fetchMapSituationsResults('piezometry', map, mapSituationStats[0]?.date)).then(() => setSituationResultLoaded(true))
        } else {
            setSituationResultLoaded(true)
        }
    }, [mapSituationStats])

    const getMaterial = (idVariousMaterial) => {
        return variousMateriels.find(m => m.id === idVariousMaterial)
    }

    const getWarningCondition = (idInstallation) => {
        const { pumpsIds, countersIds, pointPumps, pointCounters } = getSitusAndIdsVarious(idInstallation, variousMatSituations)
        const ids = [...pumpsIds, ...countersIds]
        const pointMats = [...pointPumps, ...pointCounters]
        const materialsSeizableIndex = ids?.map(id => getMaterial(id))?.filter(m => materielsTypeForIndex?.includes(m?.materielType))
        if (materialsSeizableIndex?.length > 0) {
            const lastIndex = orderBy(exploitation.link_chronicles.filter((c) => materialsSeizableIndex.map(m => m.id).includes(c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX), 'measureDate', 'desc')[0]
            if (lastIndex) {
                return { idMatLastChronicle: lastIndex.idMat, days: lastIndex.measureDate, important: true, estim: false }
            }
            const lastAffectIndex = orderBy(pointMats.filter(m => materialsSeizableIndex.some(mat => mat.id === m.idVarious)), 'situationDate', 'desc')[0]
            if (lastAffectIndex) {
                return materialsSeizableIndex?.length === 1 ? { idMatLastChronicle: lastAffectIndex.idVarious, days: lastAffectIndex.situationDate, important: false, estim: false } : { days: lastAffectIndex.situationDate, important: false, estim: false }
            }
            return { important: false, estim: false }
        }
        const lastEstim = orderBy(exploitation.link_chronicles.filter((c) => ids.includes(c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM), 'measureDate', 'desc')[0]
        if (lastEstim) {
            return { days: lastEstim.endDate, important: true, estim: true }
        }
        const lastAffectEstim = orderBy(pointMats.filter(m => materialsSeizableIndex.some(mat => mat.id !== m.idVarious)), 'situationDate', 'desc')[0]
        if (lastAffectEstim) {
            return { days: lastAffectEstim.situationDate, important: false, estim: true }
        }
        return { noIndex: true, important: false, }
    }

    const getPoints = () => (
        orderPointsList(compact(exploitation?.link_samplings?.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            const city = citiesIndex[point?.townCode] || {}
            if (point) {
                const { idMatLastChronicle, days, important, estim, noIndex } = getWarningCondition(point.id)
                const { warnDate, warning } = days ?
                    getDelaySinceLastEntry(days, point)
                    :
                    { warnDate: null, warning: true }
                const stateCode = linkPoint.stateCode
                const pointFormatted = {
                    ...point,
                    days: warnDate,
                    idMatLastChronicle,
                    warning: (noIndex ||stateCode !== POINT_STATUS_OBJ.USED) ? true : warning,
                    important: stateCode !== POINT_STATUS_OBJ.USED ? false : important,
                    estim,
                    noIndex,
                    stateCode,
                    stateLabel: getStateLabel(stateCode),
                    cityName: city.name,
                }
                return pointFormatted
            }
            return null
        })))
    )

    const getName = (point) => (
        <Grid2 container>
            <Grid2 size={12}>{point?.name}</Grid2>
            <Grid2 size={12}>{point?.code}</Grid2>
            <Grid2 size={12}>{`${point?.cityName ?? ''}${point?.townCode ? `, ${point?.townCode}` : ''}`}</Grid2>
        </Grid2>
    )

    const getState = (point) => {
        if (!situationsLoaded || !situationResultLoaded) {
            return <LoadingCard />
        }
        const station = allLinkedStations?.filter(s => s.id === point.id && s.stationLinkedType === STATION_TYPE_CONSTANT.PIEZOMETRY)[0] || {}
        const situation = mapSituationResults?.filter(s => s.id === station.stationLinkedId)[0] || {}
        const ipsColor = situation?.trendColor
        const ipsLabel = situation?.trendLabel
        return (
            <Grid2
                container
                alignItems='center'
                flexWrap='nowrap'
                columnGap= '5px'
                fontSize='11px'
                lineHeight='16px'
                sx={{ border: `1px solid ${ipsColor || 'black'}`, borderRadius: '5px', backgroundColor: `rgba(${convertToRGB(ipsColor || '#000000')}, 0.1)`, padding: '8px 5px' }}
            >
                <Grid2 size='auto'>{getIPSIconDesktop(ipsLabel, ipsColor)}</Grid2>
                <Grid2 size={9} container direction='column' justifyContent='space-evenly' sx={{ height: '100%' }}>
                    <Grid2>{i18n.level}</Grid2>
                    <Grid2>{getIPSLabel(ipsLabel)}</Grid2>
                </Grid2>
            </Grid2>
        )
    }

    const getSitu = (point) => {
        const waterTurnsRestriction = getWaterTurnsRestriction(point)
        const restrictionLabel = waterTurnsRestriction?.label || i18n.normale
        const restrictionColor = waterTurnsRestriction?.color || RESTRICTION_COLOR
        return (
            <Grid2 container alignItems={'center'}>
                <Grid2 container size={12} alignItems='center' spacing={0.1}>
                    <Grid2 container size={'auto'} >{getRestrictionIconV2(waterTurnsRestriction?.level, { height: '20px', color: restrictionColor })}</Grid2>
                    <Grid2 container size={'grow'} fontSize={14} color={restrictionColor}>{restrictionLabel}</Grid2>
                    {waterTurnsRestriction?.percentRestriction && point.annualWeeklyVolume ? <Grid2 container size={12} fontSize={12} sx={{ textDecoration: 'underline', color: darkRed }}>{i18n.checkYourVHA}</Grid2> : null}
                </Grid2>
            </Grid2>
        )
    }

    const getVolumes = (point) => {
        const askedYear = Number(moment().format('YYYY'))
        const askedVolume = (exploitationVolumes || []).filter(v => v.askedYear === askedYear && v.idInstallation === point.id).reduce((acc, v) => acc + (v.askedVolume || 0), 0)
        const attributedVolume = (exploitationVolumes || []).filter(v => v.askedYear === askedYear && v.idInstallation === point.id).reduce((acc, v) => acc + (v.attributedVolume || 0), 0)
        const authVolume = (exploitationVolumes || []).filter((v) => v.askedYear === askedYear && v.idInstallation === point.id).reduce((acc, v) => acc + (v.authorizedVolume || 0), 0)
        const drawnedVolume = uniqWith((exploitation.link_conso_real || []).filter((c) => c.idInstallation === point.id && c.year === moment().year()).map(c => omit(c, 'idInstallation')), (a, b) => a.idMat === b.idMat && a.year === b.year && a.volume === b.volume).reduce((acc, v) => acc + (v.volume || 0), 0)
        const volumeLeft = (authVolume - drawnedVolume)
        const percentLeft = authVolume === 0 ? undefined : (volumeLeft / authVolume) * 100
        return {
            askedVolume: askedVolume > 0 ? askedVolume : undefined,
            attributedVolume: attributedVolume > 0 ? attributedVolume : undefined,
            authVolume: authVolume > 0 ? authVolume : undefined,
            drawnedVolume,
            percentLeft
        }
    }

    const getStatus = (point) => {
        const { askedVolume, attributedVolume, authVolume } = getVolumes(point)
        if (authVolume) {
            return (
                <Grid2 container alignItems='center'flexWrap='nowrap'>
                    <Check sx={{ fontSize: '20px' }} /> {i18n.authorized}
                </Grid2>
            )
        }
        if (attributedVolume) {
            return (
                <Grid2 container alignItems='center'flexWrap='nowrap'>
                    <HourglassTop /> {i18n.attributed}
                </Grid2>
            )
        }
        if (askedVolume) {
            return (
                <Grid2 container alignItems='center'flexWrap='nowrap'>
                    <QuestionMark /> {i18n.asked}
                </Grid2>
            )
        }
        return undefined
    }


    const indexDeclaration = (point) => {
        if (point.warning) {
            return (
                point.important ?
                    (<RedButton
                        sx={{
                            weight: '500',
                            minHeight: '30px',
                            fontSize: '12px',
                            lineHeight: '16px'
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            if (point.idMatLastChronicle) {
                                setIndexedPoint(point)
                                setOpenModalNewIndex(true)
                            } else {
                                navigate(`/index/${point.id}/materiels/`)
                            }
                        }}
                    >
                        {template(point.estim ? i18n.noEstimSinceXDays : i18n.noIndexSinceXDays)({ days: moment().diff(point.days, 'days') })}
                    </RedButton>)
                    :
                    (
                        <Grid2 container sx={{ alignItems: 'center' }}>
                            <OrangeButton
                                noFullWidth
                                sx={{
                                    weight: '500',
                                    minHeight: '30px',
                                    fontSize: '12px',
                                    lineHeight: '16px'
                                }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (point.idMatLastChronicle) {
                                        setIndexedPoint(point)
                                        setOpenModalNewIndex(true)
                                    } else {
                                        navigate(`/index/${point.id}/materiels/`)
                                    }
                                }}
                            >
                                {point.estim || point.noIndex ? i18n.enterEstimQuestion : i18n.enterIndexQuestion}
                            </OrangeButton>
                        </Grid2>
                    )
            )
        }

        return (
            <Grid2 container columnGap='16px' flexWrap='nowrap'>
                <Grid2 container sx={{ alignItems: 'center' }} >
                    <Grid2 ><Today sx={{ height: 20, color: textColor }}/></Grid2>
                    <Grid2 sx={{ color: textColor, fontSize: '14px', lineHeight: '20px' }} >{moment(point.days).format('DD/MM/YYYY')}</Grid2>
                </Grid2>
                <MainButton
                    noFullWidth
                    sx={{
                        padding: '6px 40px',
                        weight: '500',
                        minHeight: '30px',
                        fontSize: '14px',
                        lineHeight: '20px'
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        if (point.idMatLastChronicle) {
                            setIndexedPoint(point)
                            setOpenModalNewIndex(true)
                        } else {
                            navigate(`/index/${point.id}/materiels/`)
                        }
                    }}
                >
                    {i18n.add}
                </MainButton>
            </Grid2>
        )
    }

    const getEventReporting = (point) => (
        <MainButton
            reverse
            sx={{ fontSize: '12px', padding: '4px 12px' }}
            onClick={(e) => {
                e.stopPropagation()
                navigate(`/installations/point/${point.id}/events/materiels`)
            }}
        >
            {i18n.reportIncident}
        </MainButton>
    )

    const getRows = () => {
        const pointsOrdered = getPoints()
        return (pointsOrdered.map((point) => {
            const { askedVolume, attributedVolume, authVolume, drawnedVolume, percentLeft } = getVolumes(point)
            const volume = authVolume || attributedVolume || askedVolume || undefined
            return {
                [i18n.nameAndId]: { value: getName(point) },
                [i18n.state]: { value: getState(point) },
                [i18n.situation]: { value: getSitu(point) },
                [i18n.status]: { value: getStatus(point) },
                [i18n.volume]: { value: volume && `${formatMilliers(volume)} m3`, textWrap: 'nowrap' },
                [i18n.drawnedVolume]: { value: `${formatMilliers(drawnedVolume)} m3`, textWrap: 'nowrap' },
                [i18n.authorizedVolumeRemaining]: { value: percentLeft ? `${round(percentLeft)}%` : undefined, textWrap: 'nowrap' },
                [i18n.indexDeclaration]: { value: indexDeclaration(point) },
                [i18n.eventReporting]: { value: getEventReporting(point) },
                onClickValue: point
            }
        })
        )
    }

    if (!pointsLoaded || Object.keys(citiesIndex).length === 0) {
        return <LoadingCard />
    }

    return (
        <Grid2 container size={12} sx={{ height: '100%', overflowY: 'hidden' }} >
            <Grid2
                container
                size={12}
                justifyContent='space-between'
                spacing={2}
                flexWrap='nowrap'
                alignItems='center'
                sx={{
                    paddingBottom: 2,
                }}
            >
                <Grid2 container size={'auto'} sx={{ fontSize: '22px', lineHeight: '28px', color: textColor }}>{i18n.installations}</Grid2>
                {nbVHAAlert ? <Grid2
                    container
                    size={{ lg: 10, xl: 'auto' }}
                    columnSpacing={1}
                    alignItems='center'
                    sx={{
                        border: `solid 1px ${darkRed}`,
                        borderRadius: 1,
                        backgroundColor: `rgba(${convertToRGB(mainRed)}, 0.1)`,
                        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                        color: darkRed,
                        fontSize: '18px',
                        textAlign: 'right'
                    }}
                >
                    <Grid2 container size={'auto'}><Error /></Grid2>
                    <Grid2 container size={'grow'} >{getI18nTitleDataLength(i18n.restrictionOnOnePointChechVHA, template(i18n.restrictionOnXPointChechVHA)({ nbInstall: nbVHAAlert }), nbVHAAlert)}</Grid2>
                </Grid2> : null}
            </Grid2>
            <Table
                grouped
                rowClickable
                onClickRow={(row) => dispatch(push(`/installations/point/${row.onClickValue.id}`))}
                sx={{ height: 'calc(100% - 4rem)', overflowY: 'auto' }}
                rows={getRows()}
                headers={[
                    [
                        { value: i18n.point, colSpan: 1, group: 0 },
                        { value: i18n.resource, colSpan: 2, group: 1 },
                        { value: i18n.samplingVolumes, colSpan: 4, group: 2 },
                        { value: i18n.actions, colSpan: 2, group: 3 }
                    ],
                    [
                        { value: i18n.nameAndId, colSpan: 1, group: 0 },
                        { value: i18n.state, colSpan: 1, group: 1 },
                        { value: i18n.situation, colSpan: 1, group: 1 },
                        { value: i18n.status, colSpan: 1, group: 2 },
                        { value: i18n.volume, colSpan: 1, group: 2 },
                        { value: i18n.drawnedVolume, colSpan: 1, group: 2 },
                        { value: i18n.authorizedVolumeRemaining, colSpan: 1, group: 2 },
                        { value: i18n.indexDeclaration, colSpan: 1, group: 3 },
                        { value: i18n.eventReporting, colSpan: 1, group: 3 }
                    ]
                ]}
                headersLabel={[i18n.nameAndId, i18n.state, i18n.situation, i18n.status, i18n.volume,
                    i18n.drawnedVolume, i18n.authorizedVolumeRemaining, i18n.indexDeclaration, i18n.eventReporting]}
            />
            {openModalNewIndex && <ModalIndexEntry
                point={indexedPoint}
                openModalNewIndex={openModalNewIndex}
                setOpenModalNewIndex={setOpenModalNewIndex}
                setIndexedPoint={setIndexedPoint}
                getMaterial={getMaterial}
            />}
        </Grid2>
    )
}

export default PointsListAppDesktop