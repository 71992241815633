import { FULL_RESET } from '../../../offline/constants/HomeConstants'
import DtoDeclaration from '../../agri/dto/enquete/DtoDeclaration'
import DtoDeclarationWithStats from '../../agri/dto/enquete/DtoDeclarationWithStats'
import DtoEnquete from '../../agri/dto/enquete/DtoEnquete'
import DtoIntervenantDeclaration from '../../agri/dto/enquete/DtoIntervenantDeclaration'
import {
    RECEIVE_DECLARATION,
    RECEIVE_DECLARATION_CONTRIBUTORS,
    RECEIVE_DECLARATIONS_STATS_EXPLOITATION,
    RECEIVE_SURVEY,
    RECEIVE_SURVEYS
} from '../constants/DeclarationConstants'

export const store = {
    declaration: {},
    declarationContributors: [],
    declarationsExploitation: [],
    survey: {},
    surveys: [],
}
export function DeclarationReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_DECLARATION:
            return {
                ...state,
                declaration: new DtoDeclaration(action.declaration),
            }
        case RECEIVE_DECLARATION_CONTRIBUTORS:
            return {
                ...state,
                declarationContributors: action.declarationContributors.map(
                    (c) => new DtoIntervenantDeclaration(c),
                ),
            }
        case RECEIVE_DECLARATIONS_STATS_EXPLOITATION:
            return {
                ...state,
                declarationsExploitation: action.declarations.map((d) => new DtoDeclarationWithStats(d)),
            }
        case RECEIVE_SURVEY:
            return {
                ...state,
                survey: new DtoEnquete(action.survey),
            }
        case RECEIVE_SURVEYS:
            return {
                ...state,
                surveys: action.surveys.map((s) => new DtoEnquete(s)),
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
