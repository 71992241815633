module.exports = {
    RECEIVE_CULTURES: 'RECEIVE_CULTURES',
    RECEIVE_CULTURES_FAMILIES: 'RECEIVE_CULTURES_FAMILIES',
    RECEIVE_CONTRIBUTOR: 'RECEIVE_CONTRIBUTOR',
    RECEIVE_CONTRIBUTORS: 'RECEIVE_CONTRIBUTORS',
    RECEIVE_USAGES: 'RECEIVE_USAGES',
    RECEIVE_SANDRE_CODES: 'RECEIVE_SANDRE_CODES',
    RECEIVE_MANAGEMENT_UNITS: 'RECEIVE_MANAGEMENT_UNITS',
    RECEIVE_ALL_WATERSHEDS: 'RECEIVE_ALL_WATERSHEDS',
    RECEIVE_WATERTURNS_RESTRICTIONS: 'RECEIVE_WATERTURNS_RESTRICTIONS',
    RECEIVE_WATERTURNS_SLOTS: 'RECEIVE_WATERTURNS_SLOTS',
    RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS: 'RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS',
    RECEIVE_MODES_IRRIGATIONS: 'RECEIVE_MODES_IRRIGATIONS',
    RECEIVE_DROUGHT_SECTORS_RESTRICTIONS: 'RECEIVE_DROUGHT_SECTORS_RESTRICTIONS',
    SANDRE: {
        USAGES: 'PIEZOMETRES_PRELEVEMENTS.USAGES',
        INTERVENANTS_TYPE_CODIFICATION: 'INTERVENANTS.TYPE_CODIFICATION',
        TYPE_PHOTO: 'TYPE_PHOTO',
        TYPE_DOCUMENT: 'TYPE_DOCUMENT',
    },
}
