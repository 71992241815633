import i18n from 'simple-react-i18n'
import {
    RECEIVE_CMS_EVENTS,
} from '../constants/CmsConstants'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { aquaFetchV2, getAuthorizationLogin } from '../../../../utils/ActionUtils'
import ToastrAction from '../../components/toasters/ToastrAction'
import LogAction from '../../../../utils/log/actions/LogAction'

const CmsAction = {
    receiveCMSEvents(cmsEvents) {
        return { type: RECEIVE_CMS_EVENTS, cmsEvents }
    },

    fetchCMSEvents(page) {
        return (dispatch) => {
            return aquaFetchV2(ApplicationConf.cms.getAll(), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then((json = []) => {
                    dispatch(CmsAction.receiveCMSEvents(json))
                    return json
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.articles} : ${err}`)
                    if (page !== 'dashboard') {
                        ToastrAction.error(i18n.fetchError + i18n.articles)
                    }
                })
        }
    },
}

export default CmsAction
