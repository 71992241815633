import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid2, Tooltip, useTheme } from '@mui/material'
import { isNil, maxBy, orderBy } from 'lodash'
import FileAction from '../referencials/documents/actions/FileAction'
import { ReactComponent as DownloadLogo } from '../../../ressources/static/svg/Download.svg'
import { getFileNatureLabel, getTypeSandreCode } from '../../../utils/FileUtils'
import { getSandreList } from '../../../utils/StoreUtils'
import LoadingCard from '../components/cards/LoadingCard'
import Table from '../components/Table'
import { darkTextColor, lightGrey, mainColor } from '../components/styled/theme'
import { FolderOff, KeyboardArrowLeft, KeyboardArrowRight, Today } from '@mui/icons-material'
import moment from 'moment'
import { PDF_EXTENSIONS } from '../referencials/documents/constants/FileConstants'
import { DECLARATION_STATUS, EXPLOITATION_STATION_TYPE } from '../agri/constants/AgriConstants'
import ModalDownloadDocument from './ModalDownloadDocument'
import DeclarationAction from '../enquete/actions/DeclarationAction'
import ExploitationAction from '../exploitation/actions/ExploitationAction'

const MyDocumentsDesktop = () => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [openModalExport, setOpenModalExport] = useState(false)
    const [declaration, setDeclaration] = useState()
    const [year, setYear] = useState()
    const [surveyLoaded, setSurveyLoaded] = useState(false)

    const {
        exploitation,
        declarationsExploitation,
        codesSandre,
        pictures,
        files,
        survey,
    } = useSelector(store => ({
        exploitation: store.ExploitationReducer.exploitation,
        declarationsExploitation: store.DeclarationReducer.declarationsExploitation,
        codesSandre: store.ReferencialReducer.codesSandre,
        pictures: store.FileReducer.pictures,
        files: store.FileReducer.files,
        survey: store.DeclarationReducer.survey,
    }), shallowEqual)

    const dispatch = useDispatch()

    const theme = useTheme()

    const getDeclarationsAndDocs = (exploit = exploitation) => {
        dispatch(FileAction.fetchPictures(exploit?.codification?.replaceAll(' ', ''), EXPLOITATION_STATION_TYPE))
        dispatch(FileAction.fetchFiles(exploit?.codification?.replaceAll(' ', ''), EXPLOITATION_STATION_TYPE))
        dispatch(DeclarationAction.fetchDeclarationsByExploitationId(exploit.idExploitation)).then(() => {
            setDataLoaded(true)
        })
    }

    useEffect(() => {
        if (exploitation.idExploitation) {
            getDeclarationsAndDocs()
        } else {
            dispatch(ExploitationAction.fetchExploitation()).then((res) => {
                getDeclarationsAndDocs(res)
            })
        }
    }, [])

    const getAttributedVolumes = (decla) => {
        return decla.link_volumes.reduce((v, l) => v + (l?.attributedVolume ?? 0), 0)
    }

    const getAuthorizedVolumes = (decla) => {
        return decla.link_volumes.reduce((v, l) => v + (l?.authorizedVolume ?? 0), 0)
    }

    const getSurvey = (id) => {
        dispatch(DeclarationAction.fetchSurvey(id)).then(() => {
            setSurveyLoaded(true)
        })
    }

    const volumesInNotification = useMemo(() => survey?.link_params?.find(p => p.parameter === 'volumesInNotification')?.value, [survey])

    const documents = [...pictures, ...files].filter((d) => d.active === '1').map(fp => {
        return { ...fp, type: getFileNatureLabel(fp.name, getSandreList(codesSandre, getTypeSandreCode(fp.fileType))), doc: true, updateDate: fp.date }
    })

    const declarations = declarationsExploitation.filter(d => d.statusCode >= DECLARATION_STATUS.SENT)
    const documentsAndDeclaration = orderBy([...documents, ...declarations], 'updateDate', 'desc')

    useEffect(() => {
        if (isNil(year)) {
            if (documentsAndDeclaration?.length) {
                setYear(maxBy(documentsAndDeclaration, 'year')?.year)
            } else {
                setYear(moment().format('YYYY'))
            }
        }
    }, [documentsAndDeclaration])

    const rows = useMemo(() => {
        if (!dataLoaded) return []
        return documentsAndDeclaration.filter(d => d.year ? d.year === Number(year) : Number(moment(d.updateDate).format('YYYY')) === Number(year)).map((d) => ({
            [i18n.documentType]: { value: d.surveyType ? i18n.declaration : i18n.other, padding: d.doc ? '16px 0': undefined },
            [i18n.documentName]: { value: d.shortName ?? `${i18n.declaration} ${d.year}`, padding: d.doc ? '16px 0': undefined },
            [i18n.format]: { value: d.surveyType ? PDF_EXTENSIONS[1] : d.name.split('.')[1], padding: d.doc ? '16px 0': undefined },
            [i18n.receptionDate]: { value: moment(d.updateDate).format('DD/MM'), padding: d.doc ? '16px 0': undefined },
            [i18n.volumes]: { value: d.surveyType ? <Grid2 container justifyContent= 'space-between'>
                <Grid2 size={6} color={lightGrey}>{i18n.asked}</Grid2><Grid2 size={6} >{d.prevVolume}</Grid2>
                <Grid2 size={6} color={lightGrey}>{i18n.attributed}</Grid2><Grid2 size={6}>{getAttributedVolumes(d)}</Grid2>
                <Grid2 size={6} color={lightGrey}>{i18n.authorized}</Grid2><Grid2 size={6}>{getAuthorizedVolumes(d)}</Grid2>
            </Grid2> : undefined, padding: d.doc ? '16px 0': undefined },
            [i18n.enquete]: { value: d.surveyName ?? undefined, padding: d.doc ? '16px 0': undefined },
            [i18n.download]: { value: <Tooltip title={i18n.download}><DownloadLogo
                className='clickable'
                style={{ height: 20 }}
                onClick={() => {
                    if (d.doc) {
                        window.open(d.url, '_system')
                    } else {
                        setOpenModalExport(true)
                        setDeclaration(d)
                        getSurvey(d.idSurvey)
                    }
                }}
                fill={darkTextColor}
            /></Tooltip>, padding: d.doc ? '16px 0': undefined, align: 'center' },
        }))
    }, [documentsAndDeclaration, dataLoaded])

    if (!dataLoaded) {
        return <LoadingCard />
    }

    return (
        <Grid2
            container
            size={12}
            sx={{
                height: '100%',
            }}
        >
            <Grid2 container alignItems='center' justifyContent='flex-start' sx={{ color: darkTextColor, paddingBottom: theme.spacing(3) }}>
                <Grid2 sx={{ paddingRight: theme.spacing(5), fontSize: '22px', lineHeight: '28px' }} >{i18n.documents}</Grid2>
                <Grid2 sx={{ paddingRight: theme.spacing(2), color: lightGrey }} ><Today sx={{ fontSize: '20px' }}/></Grid2>
                <Grid2
                    sx={{ paddingRight: theme.spacing(2) }}
                    className='clickable'
                    onClick={() => setYear(Number(year)-1)}
                >
                    <KeyboardArrowLeft sx={{ fontSize: '20px' }}/>
                </Grid2>
                <Grid2 sx={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }} >{year}</Grid2>
                <Grid2
                    sx={{ paddingLeft: theme.spacing(2) }}
                    className='clickable'
                    onClick={() => setYear(Number(year)+1)}
                >
                    <KeyboardArrowRight sx={{ fontSize: '20px' }}/>
                </Grid2>
            </Grid2>
            {rows.length ?
                <Grid2 container size={12} sx={{ height: 'calc(100% - 2.5rem)' }}>
                    <Table rows={rows}
                        headers={[[
                            { value: i18n.documentType, colSpan: 1 }, { value: i18n.documentName, colSpan: 1 },
                            { value: i18n.format, colSpan: 1 }, { value: i18n.receptionDate, colSpan: 1 },
                            { value: i18n.volumes, colSpan: 1 }, { value: i18n.enquete, colSpan: 1 },
                            { value: i18n.download, colSpan: 1 }]]}
                        headersLabel={[i18n.documentType, i18n.documentName, i18n.format, i18n.receptionDate,
                            i18n.volumes, i18n.enquete, i18n.download]}
                    />
                </Grid2>
                :
                <Grid2 container size={12} sx={{ height: 'calc(100% - 2.5rem)', alignContent: 'center' }}>
                    <Grid2 container size={12} sx={{ color: mainColor, paddingBottom: '12px', justifyContent: 'center' }}><FolderOff sx={{ fontSize: '70px' }}/></Grid2>
                    <Grid2 container size={12} sx={{ fontSize: '24px', color: darkTextColor, justifyContent: 'center' }} >{i18n.noDocument}</Grid2>
                </Grid2>
            }
            <ModalDownloadDocument
                open={openModalExport}
                surveyLoaded={surveyLoaded}
                onClose={() => {
                    setOpenModalExport()
                    setDeclaration()
                    setSurveyLoaded(false)
                }}
                declaration={declaration}
                survey={survey}
                volumesInNotification={volumesInNotification}
            />
        </Grid2>
    )
}

export default MyDocumentsDesktop
