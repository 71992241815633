import { FULL_RESET } from '../../../offline/constants/HomeConstants'
import DtoWaterTurn from '../../agri/dto/DtoWaterTurn'
import DtoDeclarationVolumes from '../../agri/dto/enquete/DtoDeclarationVolumes'
import DtoExploitation from '../../agri/dto/exploitation/DtoExploitation'
import {
    RECEIVE_EXPLOITATION,
    RECEIVE_EXPLOITATION_VOLUMES,
    RECEIVE_EXPLOITATIONS_DATA,
    RECEIVE_WATERTURNS,
    RECEIVE_WATERTURNS_EXPLOITATION,
    RESET_EXPLOIT
} from '../constants/ExploitationConstants'

export const store = {
    allWaterTurns: [],
    exploitation: {},
    exploitationsExportFullData: [],
    exploitationVolumes: [],
    exploitationWaterTurns: [],
}
export function ExploitationReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_WATERTURNS_EXPLOITATION:
            return {
                ...state,
                exploitationWaterTurns: action.waterTurns.map((w) => new DtoWaterTurn(w)),
            }
        case RECEIVE_EXPLOITATIONS_DATA:
            return {
                ...state,
                exploitationsExportFullData: action.exploitationsExportFullData,
            }
        case RECEIVE_EXPLOITATION:
            return {
                ...state,
                exploitation: new DtoExploitation(action.exploitation || {}),
            }
        case RECEIVE_EXPLOITATION_VOLUMES:
            return {
                ...state,
                exploitationVolumes: action.exploitationVolumes.map((v) => new DtoDeclarationVolumes(v))
            }
        case RESET_EXPLOIT:
            return {
                ...store,
                exploitationsExportFullData: state.exploitationsExportFullData,
            }
        case RECEIVE_WATERTURNS:
            return {
                ...state,
                allWaterTurns: action.waterTurns.map((w) => new DtoWaterTurn(w)),
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
