'use strict'

import ApplicationConf from '../../../../conf/ApplicationConf'
import { CONTENT_PATH } from '../../../../conf/basepath'
import { aquaFetchV2, getAuthorization } from '../../../../utils/ActionUtils'
import LogAction from '../../../../utils/log/actions/LogAction'
import ToastrAction from '../../components/toasters/ToastrAction'
import {
    RECEIVE_DECLARATION,
    RECEIVE_DECLARATION_CONTRIBUTORS,
    RECEIVE_DECLARATIONS_STATS_EXPLOITATION,
    RECEIVE_SURVEY,
    RECEIVE_SURVEYS
} from '../constants/DeclarationConstants'
import i18n from 'simple-react-i18n'

const DeclarationAction = {

    receiveDeclaration(declaration) {
        return { type: RECEIVE_DECLARATION, declaration }
    },

    fetchDeclarationByExploitationId(id) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.agri.declarationExploitation(id), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().DeclarationReducer.declaration })
                .then((json = {}) => {
                    if (json && json.idDeclaration) {
                        dispatch(DeclarationAction.receiveDeclaration(json))
                        return json
                    }
                    return false
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.declaration} : ${err}`)
                })
        }
    },

    updateDeclaration(declaration, callback = () => {}) {
        return (dispatch) => {
            return aquaFetchV2(ApplicationConf.agri.declaration(declaration.idDeclaration), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(declaration),
            })
                .then(() => {
                    dispatch(DeclarationAction.fetchDeclarationByExploitationId(declaration.idExploitation)).then(() => {
                        callback()
                    })
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.declaration} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.declaration)
                })
        }
    },

    receiveDeclarationContributors(declarationContributors) {
        return { type: RECEIVE_DECLARATION_CONTRIBUTORS, declarationContributors }
    },

    fetchDeclarationContributors(idEnquete) {
        return (dispatch) => {
            return aquaFetchV2(ApplicationConf.agri.getdeclarationContributors(idEnquete), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then((json = []) => {
                    dispatch(DeclarationAction.receiveDeclarationContributors(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.contributors} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.contributors)
                })
        }
    },

    updateDeclarationContributor(idEnquete, contributor, callback = () => {}) {
        return (dispatch) => {
            return aquaFetchV2(ApplicationConf.agri.declarationContributor(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(contributor),
            })
                .then((id) => {
                    dispatch(DeclarationAction.fetchDeclarationContributors(idEnquete)).then(() => {
                        callback(id)
                    })
                    return id
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.contributor} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.contributor)
                })
        }
    },

    receiveSurveys(surveys) {
        return { type: RECEIVE_SURVEYS, surveys }
    },

    fetchSurveys() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.agri.surveys(), {
                method: 'GET',
                headers: getAuthorization()
            }, { defaultResult: getState().DeclarationReducer.surveys })
                .then(json => {
                    dispatch(DeclarationAction.receiveSurveys(json))
                })
                .catch(err => {
                    LogAction.logError(`${i18n.fetchError}${i18n.enquete} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.enquete)
                })
        }
    },

    receiveSurvey(survey) {
        return { type: RECEIVE_SURVEY, survey }
    },

    fetchSurvey(id) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.agri.survey(id), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().DeclarationReducer.surveys.find((s) => s.idSurvey === id) })
                .then(json => dispatch(DeclarationAction.receiveSurvey(json)))
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError}${i18n.enquete} : ${err}`))
                    ToastrAction.error(i18n.fetchError + i18n.enquete)
                })
        }
    },

    exportDeclarationFull(id) {
        ToastrAction.info(i18n.loadingDocument)
        return () => {
            return aquaFetchV2(ApplicationConf.agri.exportDeclarationFull(id), {
                method: 'GET',
                headers: getAuthorization()
            })
                .then((json) => {
                    const pathPDF = `${CONTENT_PATH}${json.targetPath}`
                    fetch(pathPDF).then((resp) => {
                        return resp.blob().then((b) => {
                            let a = document.createElement('a')
                            a.href = URL.createObjectURL(b)
                            const fileName = json.targetPath.split('/')[1]
                            a.setAttribute('download', fileName)
                            a.click()
                        })
                    })
                })
                .catch(err => {
                    LogAction.logError(`${i18n.fetchError}${i18n.declaration} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.declaration)
                })
        }
    },

    receiveDeclarationsExploitation(declarations) {
        return { type: RECEIVE_DECLARATIONS_STATS_EXPLOITATION, declarations }
    },

    fetchDeclarationsByExploitationId(id) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.agri.declarationsExploitation(id), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().DeclarationReducer.declarationsExploitation })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.declarations} : ${err}`)
                })
                .then((json = []) => {
                    dispatch(DeclarationAction.receiveDeclarationsExploitation(json))
                })
        }
    },
}

export default DeclarationAction
