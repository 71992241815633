/* eslint-disable camelcase */
'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { aquaFetchV2, checkAuth, checkError, getAuthorization } from '../../../../utils/ActionUtils'
import { CONTENT_PATH } from '../../../../conf/basepath'
import ToastrAction from '../../components/toasters/ToastrAction'
import LogAction from '../../../../utils/log/actions/LogAction'

const AgriAction = {
    calculVolumeUsage(usage) {
        return () => {
            return fetch(ApplicationConf.agri.calculVolume(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(usage),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    return json || 0
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.errorCalculVolume} : ${err}`)
                    ToastrAction.error(i18n.errorCalculVolume)
                })
        }
    },

    calculPointVolumeReal(idPoint, idExploitation, startDate, endDate) {
        return () => {
            return aquaFetchV2(ApplicationConf.agri.calculPointVolumeReal(idPoint, idExploitation, startDate, endDate), {
                method: 'GET',
                headers: getAuthorization(),
            }, { crashOnOffline: false })
                .then((json) => {
                    return json
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.errorCalculVolume} : ${err}`)
                    ToastrAction.error(i18n.errorCalculVolume)
                })
        }
    },

    getNotifEdition(idExploitation, year) {
        ToastrAction.info(i18n.loadingDocument)
        return () => {
            return fetch(ApplicationConf.agri.editionNotification(idExploitation, year), {
                method: 'GET',
                headers: getAuthorization()
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    const pathPDF = `${CONTENT_PATH}${json.targetPath}`
                    fetch(pathPDF).then((resp) => {
                        return resp.blob().then((b) => {
                            let a = document.createElement('a')
                            a.href = URL.createObjectURL(b)
                            const fileName = json.targetPath.split('/')[1]
                            a.setAttribute('download', fileName)
                            a.click()
                        })
                    })
                })
                .catch(err => {
                    LogAction.logError(`${i18n.fetchError}${i18n.notification} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.notification)
                })
        }
    },
}

export default AgriAction
