'use strict'

import ApplicationConf from '../../../../conf/ApplicationConf'
import { aquaFetchV2, getAuthorization } from '../../../../utils/ActionUtils'
import LogAction from '../../../../utils/log/actions/LogAction'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_EXPLOITATION,
    RECEIVE_EXPLOITATION_VOLUMES,
    RECEIVE_EXPLOITATIONS_DATA,
    RECEIVE_WATERTURNS,
    RECEIVE_WATERTURNS_EXPLOITATION,
    RESET_EXPLOIT
} from '../constants/ExploitationConstants'
import ToastrAction from '../../components/toasters/ToastrAction'

const ExploitationAction = {
    receiveExploitation(exploitation) {
        return { type: RECEIVE_EXPLOITATION, exploitation }
    },

    fetchExploitation() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.agri.exploitations(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ExploitationReducer.exploitation })
                .then((json = {}) => {
                    dispatch(ExploitationAction.receiveExploitation(json))
                    return json
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.exploitation} : ${err}`)
                })
        }
    },

    receiveExploitationsExportFullData(exploitationsExportFullData) {
        return { type: RECEIVE_EXPLOITATIONS_DATA, exploitationsExportFullData }
    },

    fetchExploitationsExportFullData() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.agri.exploitationsExportFull(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ExploitationReducer.exploitationsExportFullData })
                .then(json => dispatch(ExploitationAction.receiveExploitationsExportFullData(json)))
                .catch(err => {
                    LogAction.logError(`${i18n.fetchError}${i18n.exploitations} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.exploitations)
                })
        }
    },

    fetchExploitationsAvailable() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.agri.exploitationsAvailable(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ExploitationReducer.exploitationsExportFullData })
                .then(json => dispatch(ExploitationAction.receiveExploitationsExportFullData(json)))
                .catch(err => {
                    LogAction.logError(`${i18n.fetchError}${i18n.exploitations} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.exploitations)
                })
        }
    },

    updateExploitation(exploitation) {
        return () => {
            return aquaFetchV2(ApplicationConf.agri.exploitation(exploitation.idExploitation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(exploitation),
            })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.exploitation} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.exploitation)
                })
        }
    },

    receiveExploitationVolumes(exploitationVolumes) {
        return { type: RECEIVE_EXPLOITATION_VOLUMES, exploitationVolumes }
    },

    fetchExploitationVolumes(idExploitation) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.agri.exploitationVolumes(idExploitation), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ExploitationReducer.exploitationVolumes })
                .then((json) => {
                    dispatch(ExploitationAction.receiveExploitationVolumes(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.exploitation} : ${err}`)
                })
        }
    },

    receiveWaterTurnsExploitation(waterTurns) {
        return { type: RECEIVE_WATERTURNS_EXPLOITATION, waterTurns }
    },

    fetchWaterTurnsExploitation(idExploitation) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.agri.waterTurns(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ idExploitations: [idExploitation] })
            }, { defaultResult: getState().ExploitationReducer.exploitationWaterTurns })
                .then((json) => {
                    dispatch(ExploitationAction.receiveWaterTurnsExploitation(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.waterTurns} : ${err}`)
                })
        }
    },

    resetAgriStore() {
        return { type: RESET_EXPLOIT }
    },

    changeExploitationAdministrator(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.changeAdministrator(id), {
                method: 'PUT',
                headers: getAuthorization(),
            })
                .then(() => dispatch(ExploitationAction.resetAgriStore()))
                .catch(err => {
                    LogAction.logError(`${i18n.updateError} : ${err}`)
                    ToastrAction.error(i18n.updateError)
                })
        }
    },
    receiveAllWaterTurns(waterTurns) {
        return { type: RECEIVE_WATERTURNS, waterTurns }
    },

    fetchAllWaterTurns() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.agri.waterTurns(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({}),
            }, { defaultResult: getState().ExploitationReducer.allWaterTurns })
                .then((json) => {
                    dispatch(ExploitationAction.receiveAllWaterTurns(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.waterTurns} : ${err}`)
                })
        }
    },
}

export default ExploitationAction
