/* eslint-disable no-underscore-dangle */
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import { Divider, Grid2, Icon, LinearProgress } from '@mui/material'
import { orderBy } from 'lodash'
import { InputRow } from '../online/components/styled/inputs'
import { searchAllCharacters } from '../../utils/StringUtil'
import { MainButton } from '../online/components/styled/buttons'
import DtoExploitation from '../online/agri/dto/exploitation/DtoExploitation'
import DtoUser from '../online/account/dto/DtoUser'
import { mainColor } from '../online/components/styled/theme'
import { hasValue } from '../../utils/NumberUtil'
import CityAction from '../online/referencials/city/actions/CityAction'
import DtoCity from '../online/referencials/city/dto/DtoCity'
import DtoWaterTurn from '../online/agri/dto/DtoWaterTurn'
import { ArrowBack } from '@mui/icons-material'
import ModalFolderDesktop from './components/ModalFolderDesktop'
import { arrayOf, instanceOf } from '../../utils/StoreUtils'
import ReferencialAction from '../online/referencials/actions/ReferencialAction'
import DtoWaterTurnRestriction from '../online/referencials/dto/DtoWaterTurnRestriction'
import DtoWaterTurnsSlot from '../online/referencials/dto/DtoWaterTurnsSlot'
import ExploitationAction from '../online/exploitation/actions/ExploitationAction'

const headersExport = ['updateDate', 'activityEndDate', 'codification', 'structureType', 'civility', 'name', 'address', 'additionnalAddress', 'inseeCode',
    'postalCode', 'city', 'phoneTel', 'mobile', 'fax', 'email', 'comment', 'siret', 'pacage', 'octagri_aura', 'octagri_local', 'muse', 'agence',
    'domestic', 'agri', 'nonAgri', 'adjourned', 'shut', 'legalRepSampler', 'legalRepExploit']


const SearchBar = ({
    onValidate,
}) => {
    const [searchValue, setSearchValue] = useState()
    return (
        <>
            <Grid2 size={10}>
                <InputRow
                    id='login'
                    label={i18n.search}
                    type='text'
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    variant='outlined'
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            onValidate(searchValue)
                        }
                    }}
                />
            </Grid2>
            <Grid2 size={2} style={{ display: 'flex' }}>
                <MainButton style={{ margin: '5px 0' }} onClick={() => onValidate(searchValue)}>
                    <Icon>search</Icon>
                </MainButton>
            </Grid2>
        </>
    )
}

SearchBar.propTypes = {
    onValidate: PropTypes.func,
}

class Admin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            openModalFolder: false,
            searchValue: '',
            exploitationUser: {},
            exploitationSelected: {},
            panel: 'list',
        }
    }

    componentDidMount() {
        const { accountUser, cities } = this.props
        if (accountUser.isAdmin === '1' || accountUser.metadata === '1') {
            if (!cities.length) {
                this.props.fetchCities().then(() => {
                    this.getDatas()
                })
            } else {
                this.getDatas()
            }
        } else {
            this.props.push('/')
        }
    }

    getDatas = () => {
        const { accountUser } = this.props
        this.props.fetchWaterTurnsRestrictions()
        this.props.fetchAllWaterTurns()
        if (accountUser.isAdmin === '1') {
            this.props.fetchExploitationsExportFullData().then(() => this.getExploitation())
        } else {
            this.props.fetchExploitationsAvailable().then(() => this.getExploitation())
        }
    }

    getExploitation = () => {
        this.props.fetchExploitation().then(() => {
            const { exploitation, exploitationsExportFullData } = this.props
            if (exploitation && exploitation.idExploitation) {
                const exploitationUser = exploitationsExportFullData.find(({ idExploitation }) => idExploitation === exploitation.idExploitation) || {}
                this.setState({ dataLoaded: true, exploitationUser })
            } else {
                this.setState({ dataLoaded: true })
            }
        })
    }

    getExploitations = () => {
        const { exploitationsExportFullData } = this.props
        const filteredData = this.getFilteredData(exploitationsExportFullData)
        return orderBy(filteredData, 'codification').map((e, i) => (
            <Grid2
                key={i}
                className='clickable'
                style={{
                    borderBottom: '1px solid grey',
                    padding: '8px 16px',
                    backgroundColor: 'white',
                }}
                onClick={() => this.openModalFolder(e)}
            >
                <Grid2><b>{e.codification || ''}</b></Grid2>
                <Grid2>
                    {`${e.city || ''} ${e.inseeCode && e.inseeCode !== 'undefined' ?
                        `[${e.inseeCode}]` :
                        ''}`}
                </Grid2>
                <Grid2>{e.name || ''}</Grid2>
            </Grid2>
        ))
    }

    openModalFolder = (exploitationSelected) => {
        this.setState({ exploitationSelected, openModalFolder: true })
    }

    getFilteredData = (data) => {
        const { searchValue, exploitationUser } = this.state
        const dataFiltered = data.filter(({ idExploitation }) => idExploitation !== exploitationUser.idExploitation)
        const includesValue = searchAllCharacters(searchValue || '')
        return dataFiltered.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (exploitation) => {
        return searchAllCharacters(headersExport.map(key => exploitation[key]))
    }

    onValidate = (searchValueTmp) => {
        this.setState({ searchValue: searchValueTmp })
    }

    getModalFolder = () => {
        const { openModalFolder, exploitationSelected } = this.state
        if (openModalFolder) {
            return (
                <ModalFolderDesktop
                    open={openModalFolder}
                    exploitation={exploitationSelected}
                    onCancel={this.onCancelModalFolder}
                    onValidate={this.onValidateModalFolder}
                />
            )
        }
        return null
    }

    onCancelModalFolder = () => {
        this.setState({ openModalFolder: false, exploitationSelected: null })
    }

    onValidateModalFolder = () => {
        const { exploitationSelected } = this.state
        this.setState({ openModalFolder: false, exploitationSelected: null })
        this.props.changeExploitationAdministrator(exploitationSelected.idExploitation).then(() => {
            this.props.push('/home')
        })
    }

    render() {
        const { panel, dataLoaded, exploitationUser } = this.state
        const hasExploit = hasValue(exploitationUser.idExploitation)

        return (
            <Grid2 size={12} container zIndex='2' sx={{ height: '100vh' }}>
                <Grid2
                    container
                    size={12}
                    direction='column'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{
                        height: '100%',
                        overflow: 'hidden',
                        backgroundColor: 'white',
                    }}
                >
                    <Grid2
                        container
                        size={12}
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        style={{
                            borderBottom: '1px solid grey',
                            height: '70px',
                            backgroundColor: mainColor,
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '1.3rem',
                            zIndex: '1000',
                            padding: '0 10px',
                        }}
                    >
                        <Grid2 size={1} className='clickable' style={{ paddingRight: '8' }} onClick={() => this.props.push('/home')}>
                            <ArrowBack sx={{ height: 25, color: 'white' }} />
                        </Grid2>
                        <Grid2 size={11} style={{ paddingLeft: 8 }}>
                            <Grid2 style={{ fontSize: 21, color: 'white' }} container justifyContent='space-between' alignItems='center'>
                                <Grid2 style={{ fontSize: 21 }}>Recherche d'exploitations</Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2 style={{ height: 'calc(100% - 70px)', width: '100%', overflow: 'hidden' }}>
                        {panel === 'list' && (
                            <>
                                <Grid2
                                    container
                                    justifyContent='space-between'
                                    alignItems='stretch'
                                    spacing={1}
                                    style={{ padding: '0 16px' }}
                                >
                                    <Grid2 size={12} style={{ fontSize: '1.3em', marginTop: 8 }}>
                                        <b>{i18n.actualFolder} :</b>
                                    </Grid2>
                                    <Grid2
                                        size={10}
                                        className={`padding-1 ${hasExploit ? 'clickable' : ''}`}
                                        onClick={hasExploit ? () => this.openModalFolder(exploitationUser) : () => { }}
                                        style={{
                                            backgroundColor: mainColor,
                                            color: 'white',
                                            borderRadius: 5,
                                        }}
                                    >
                                        {hasExploit ? (
                                            <>
                                                <Grid2><b>{exploitationUser.codification || ''}</b></Grid2>
                                                <Grid2>
                                                    {`${exploitationUser.city || ''} ${exploitationUser.inseeCode && exploitationUser.inseeCode !== 'undefined' ?
                                                        `[${exploitationUser.inseeCode}]` :
                                                        ''}`}
                                                </Grid2>
                                                <Grid2>{exploitationUser.name || ''}</Grid2>
                                            </>
                                        ) : (
                                            <h4>{i18n.noFolder}</h4>
                                        )}
                                    </Grid2>
                                    <Grid2 size={2} style={{ display: 'flex', padding: '0 4' }}>
                                        {hasExploit && (
                                            <MainButton style={{ margin: 0 }} onClick={() => this.props.push('/home')}>
                                                <Icon>arrow_forward</Icon>
                                            </MainButton>
                                        )}
                                    </Grid2>
                                    <Grid2 size={12} style={{ margin: '10 0 5' }}>
                                        <Divider style={{ backgroundColor: 'grey' }} />
                                    </Grid2>
                                    <SearchBar onValidate={(v) => this.onValidate(v)} />
                                </Grid2>
                                <div style={{ height: '80%', overflowY: 'auto', padding: '0 16px' }}>
                                    {dataLoaded ? this.getExploitations() : (
                                        <LinearProgress />
                                    )}
                                </div>
                            </>
                        )}
                        {this.getModalFolder()}
                    </Grid2>
                </Grid2>
            </Grid2>
        )
    }
}

Admin.propTypes = {
    accountUser: instanceOf(DtoUser),
    push: PropTypes.func,
    fetchExploitationsExportFullData: PropTypes.func,
    fetchExploitationsAvailable: PropTypes.func,
    fetchExploitation: PropTypes.func,
    changeExploitationAdministrator: PropTypes.func,
    fetchWaterTurnsRestrictions: PropTypes.func,
    fetchAllWaterTurns: PropTypes.func,
    fetchCities: PropTypes.func,
    exploitationsExportFullData: PropTypes.arrayOf(PropTypes.shape({})),
    exploitation: instanceOf(DtoExploitation),
    cities: arrayOf(DtoCity),
    citiesIndex: PropTypes.objectOf(instanceOf(DtoCity)),
    allWaterTurns: arrayOf(DtoWaterTurn),
    waterTurnsRestrictions: PropTypes.objectOf(instanceOf(DtoWaterTurnRestriction)),
    waterTurnsSlots: arrayOf(DtoWaterTurnsSlot),
}

const mapDispatchToProps = {
    fetchExploitationsExportFullData: ExploitationAction.fetchExploitationsExportFullData,
    fetchExploitationsAvailable: ExploitationAction.fetchExploitationsAvailable,
    fetchExploitation: ExploitationAction.fetchExploitation,
    changeExploitationAdministrator: ExploitationAction.changeExploitationAdministrator,
    fetchWaterTurnsRestrictions: ReferencialAction.fetchWaterTurnsRestrictions,
    fetchAllWaterTurns: ExploitationAction.fetchAllWaterTurns,
    fetchCities: CityAction.fetchCities,
    push,
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        exploitationsExportFullData: store.ExploitationReducer.exploitationsExportFullData,
        exploitation: store.ExploitationReducer.exploitation,
        allWaterTurns: store.ExploitationReducer.allWaterTurns,
        waterTurnsRestrictions: store.ReferencialReducer.waterTurnsRestrictions,
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        waterTurnsSlots: store.ReferencialReducer.waterTurnsSlots,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)