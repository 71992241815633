import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import { push } from '@lagunovsky/redux-react-router'
import LoadingCard from '../components/cards/LoadingCard'
import { Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import { MainButton } from '../components/styled/buttons'
import { DECLARATION_STATUS, SURVEY_TYPE } from '../agri/constants/AgriConstants'
import { textColor } from '../components/styled/theme'
import DeclarationAction from './actions/DeclarationAction'
import ExploitationAction from '../exploitation/actions/ExploitationAction'

const HomeSurvey = () => {
    const [dataLoaded, setDataLoaded] = useState(false)

    const {
        survey,
        declaration,
        exploitation,
    } = useSelector((store) => ({
        survey: store.DeclarationReducer.survey,
        declaration: store.DeclarationReducer.declaration,
        exploitation: store.ExploitationReducer.exploitation
    }), shallowEqual)

    const dispatch = useDispatch()

    const getDeclaration = () => {
        dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation?.idExploitation)).then(() => {
            dispatch(DeclarationAction.fetchDeclarationByExploitationId(exploitation?.idExploitation)).then(() => {
                if (declaration.idDeclaration) {
                    dispatch(DeclarationAction.fetchSurvey(declaration.idSurvey)).then(() => {
                        setDataLoaded(true)
                    })
                } else {
                    dispatch(push('/home'))
                }
            })
        })
    }

    useEffect(() => {
        if (!exploitation?.idExploitation) {
            dispatch(ExploitationAction.fetchExploitation()).then(() => {
                getDeclaration()
            })
        } else {
            getDeclaration()
        }
    }, [])

    const startDeclaration = () => {
        dispatch(DeclarationAction.updateDeclaration({ ...declaration, lastStep: 1, statusCode: DECLARATION_STATUS.STARTING })).then(() => {
            dispatch(DeclarationAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then(() => {
                dispatch(push('/declaration'))
            })
        })
    }

    if (!dataLoaded) {
        return <LoadingCard />
    }

    return (
        <Grid2
            container
            rowGap='6vh'
            justifyContent='center'
            alignContent='center'
            sx={{
                maxHeight: 'calc(100% - 4vh)',
                overflowY: 'hidden',
                width: '100%',
            }}
        >
            <Grid2 container justifyContent='center' rowGap='2vh' >
                <Grid2 size={12} sx={{ textAlign: 'center', fontSize: '22px', lineHeight: '28px', color: textColor }}>
                    {`${i18n.welcomOnSurvey} ${survey.surveyType === SURVEY_TYPE.ANNUAL_SURVEY ? i18n.yearlyF : i18n.intermediate} ${survey.year}`}
                </Grid2>
                <Grid2 size={12} sx={{ textAlign: 'center', fontSize: '16px', lineHeight: '24px', color: textColor }}>
                    {i18n.enterSurveyTill} <b>{moment(survey.endDate).format('dddd Do MMMM YYYY')}</b>
                </Grid2>
                <MainButton onClick={startDeclaration} noFullWidth sx={{ width: 'auto', margin: 0 }}>{i18n.start}</MainButton>
            </Grid2>
        </Grid2>
    )
}

export default HomeSurvey