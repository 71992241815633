/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Checkbox, FormControl, FormControlLabel, Grid2, InputLabel, MenuItem, Select } from '@mui/material'
import { Close, Done, EditOutlined } from '@mui/icons-material'
import { LightCard } from '../styled/grid'
import LoadingCard from './LoadingCard'
import { DECLARATION_LINK_TYPE, MAT_CATEGORY } from '../../agri/constants/AgriConstants'
import { InputRow } from '../styled/inputs'
import { hasValue } from '../../../../utils/NumberUtil'
import MaterielAction from '../../referencials/materiels/actions/MaterielAction'
import moment from 'moment'
import { useParams } from 'react-router'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoVariousMateriel from '../../referencials/materiels/dto/DtoVariousMateriel'
import DeclarationAction from '../../enquete/actions/DeclarationAction'

const CounterCardDecla = ({ counter, noLightCard, startEditMode, sx }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [updatedCounter, setUpdatedCounter] = useState(counter)
    const [editMode, setEditMode] = useState(startEditMode)

    const params = useParams()

    const {
        variousMatTypes,
        codesSandre,
        declaration,
        variousMateriels,
        exploitation,
    } = useSelector(store => ({
        variousMatTypes: store.MaterielReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
        declaration: store.DeclarationReducer.declaration,
        variousMateriels: store.MaterielReducer.variousMateriels,
        exploitation: store.ExploitationReducer.exploitation
    }), shallowEqual)

    const dispatch = useDispatch()

    const matType = useMemo(() => variousMatTypes.find((t) => t.materielType === updatedCounter.materielType), [updatedCounter])
    const counterCountingTypes = useMemo(() => codesSandre.filter((c) => c.field === 'COMPTAGES.TYPE'))
    const counterCountingType = useMemo(() => counterCountingTypes.find((c) => c.code === updatedCounter.mobilityCode), [updatedCounter])

    const getRepartition = useMemo(() => (
        declaration.link_declarationInstallation.find(instal => instal.idInstallation === Number(params.id))
            ?.link_equipments.find(link => link.idMatAttachment === updatedCounter.id)?.assignementRate
        ??
        exploitation.link_repartition_materiels.find(link =>
            link.linkType === DECLARATION_LINK_TYPE.COUNTER_PUMP && link.idElement1 === Number(params.id)
        )?.repartition
    ), [declaration, updatedCounter])

    /* const getIdCounter = useMemo(() => (
        compact([
            ...declaration.link_declarationInstallation.find(instal => instal.idInstallation === Number(params.id))
                ?.link_equipments.filter(link => link.idMatAttachment === updatedCounter.id).map(link => link.idMat),
        ])
    ), [variousMateriels]) */

    const updateCounter = () => {
        setIsLoading(true)
        dispatch(MaterielAction.updateVariousMateriel({ ...variousMateriels.find(m => m.id === updatedCounter.id), name: updatedCounter.name }, () => {
            dispatch(DeclarationAction.updateDeclaration({
                ...declaration,
                link_counters: [
                    ...declaration.link_counters.filter(p => p.id !== updatedCounter.id),
                    {
                        ...updatedCounter,
                        idExploitation: declaration.idExploitation,
                        idSurvey: declaration.idSurvey,
                        idInstallation: Number(params.id),
                    }
                ]
            })).then(() => {
                setIsLoading(false)
            })
        }))
    }

    const content = () => {
        if (isLoading) {
            return <LoadingCard />
        }
        return (
            <Grid2 container size={12} direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
                <Grid2 container size={12} rowGap='1vh'>
                    <Grid2 container size={12} justifyContent='space-between' alignItems='center'>
                        <Grid2 sx={{ fontSize: '18px' }}><b>{i18n.counter}</b></Grid2>
                        <EditOutlined className='clickable' onClick={() => setEditMode(true)}/>
                    </Grid2>
                    {updatedCounter?.name ?
                        <Grid2 sx={{ fontSize: '18px', wordBreak: 'break-word' }}>
                            {updatedCounter?.name}<br/>{updatedCounter?.reference}
                        </Grid2>
                        :
                        <Grid2 sx={{ fontSize: '18px' }}>
                            {updatedCounter?.reference}
                        </Grid2>
                    }
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.type}</Grid2>
                        <Grid2 className='bold'>{matType?.name || '-'}</Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.countingType}</Grid2>
                        <Grid2 className='bold'>{counterCountingType?.name || '-'}</Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.marque}</Grid2>
                        <Grid2 className='bold'>{updatedCounter?.brand ?? '-'}</Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.reference}</Grid2>
                        <Grid2 className='bold'>{updatedCounter?.serialNumber ?? '-'}</Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.coefLecture}</Grid2>
                        <Grid2 className='bold'>{updatedCounter?.readingCoefficient ?? '-'}</Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.installationDate}</Grid2>
                        <Grid2 className='bold'>{updatedCounter?.installationDate ? moment(updatedCounter.installationDate).format('DD/MM/YYYY') : '-'}</Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.decommisionDate}</Grid2>
                        <Grid2 className='bold'>{updatedCounter?.revisionDate ? moment(updatedCounter.revisionDate).format('DD/MM/YYYY') : '-'}</Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.sharedEquipment}</Grid2>
                        <Grid2 className='bold'>{updatedCounter?.isShared ? i18n.yes : i18n.no}</Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.forRent}</Grid2>
                        <Grid2 className='bold'>{updatedCounter?.rent ? i18n.yes : i18n.no}</Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.affectedToPump}</Grid2>
                        <Grid2 className='bold'>-</Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2>{i18n.tauxAffectation}</Grid2>
                        <Grid2 className='bold'>{getRepartition ? `${getRepartition}%` : '-'}</Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        )
    }

    const editableContent = () => (
        <Grid2 container size={12} sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', rowGap: '1.5vh' }}>
            <Grid2 container size={12} justifyContent='space-between' alignItems='center'>
                <Grid2 sx={{ fontSize: '18px' }}><b>{i18n.counter}</b> {updatedCounter?.reference}</Grid2>
                <Grid2>
                    <Close className='clickable'
                        onClick={() => {
                            setEditMode(false)
                            setUpdatedCounter(counter)
                        }}
                    />
                    <Done className='clickable' onClick={() => {
                        setEditMode(false)
                        updateCounter()
                    }} />
                </Grid2>
            </Grid2>
            <InputRow
                label={i18n.name}
                value={updatedCounter.name}
                onChange={(e) => setUpdatedCounter({
                    ...updatedCounter,
                    name: e.target.value
                })}
            />
            <FormControl fullWidth>
                <InputLabel id={i18n.type}>{i18n.type}</InputLabel>
                <Select
                    labelId={i18n.type}
                    label={i18n.type}
                    value={updatedCounter.materielType}
                    onChange={(e) => setUpdatedCounter({ ...updatedCounter, materielType: hasValue(e.target.value) ? Number(e.target.value) : null })}
                >
                    {
                        variousMatTypes.map(type => type.category === MAT_CATEGORY.COUNTER && (
                            <MenuItem
                                value={type.materielType}
                                style={{ maxWidth: '100%' }}
                            >
                                {type.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <InputRow
                label={i18n.marque}
                value={updatedCounter.brand}
                onChange={(e) => setUpdatedCounter({
                    ...updatedCounter,
                    brand: e.target.value
                })}
            />
            <InputRow
                label={i18n.serialNumber}
                value={updatedCounter.serialNumber}
                onChange={(e) => setUpdatedCounter({
                    ...updatedCounter,
                    serialNumber: e.target.value
                })}
            />
            <InputRow
                type='Number'
                label={i18n.coefLecture}
                value={updatedCounter.readingCoefficient}
                onChange={(e) => setUpdatedCounter({
                    ...updatedCounter,
                    readingCoefficient: Number(e.target.value)
                })}
            />
            <Grid2 container size={12} justifyContent='space-between'>
                <Grid2 size={5.9}>
                    <InputRow
                        type='date'
                        label={i18n.installationDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={updatedCounter?.installationDate ? moment(updatedCounter?.installationDate).format('YYYY-MM-DD') : null}
                        onChange={(e) => setUpdatedCounter({
                            ...updatedCounter,
                            installationDate: e.target.value ? moment(e.target.value, 'YYYY-MM-DD').valueOf() : null
                        })}
                    />
                </Grid2>
                <Grid2 size={5.9}>
                    <InputRow
                        type='date'
                        label={i18n.revisionDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={updatedCounter?.revisionDate ? moment(updatedCounter?.revisionDate).format('YYYY-MM-DD') : null}
                        onChange={(e) =>
                            setUpdatedCounter({
                                ...updatedCounter,
                                revisionDate: e.target.value ? moment(e.target.value, 'YYYY-MM-DD').valueOf() : null
                            })
                        }
                    />
                </Grid2>
                <FormControlLabel
                    control={
                        <Checkbox
                            value={updatedCounter?.isShared || null}
                            checked={updatedCounter.isShared}
                            onChange={() => setUpdatedCounter({ ...updatedCounter, isShared: !updatedCounter.isShared })}
                        />
                    }
                    label={i18n.sharedEquipment}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            value={updatedCounter?.rent || null}
                            checked={updatedCounter.rent}
                            onChange={() => setUpdatedCounter({ ...updatedCounter, rent: !updatedCounter.rent })}
                        />
                    }
                    label={i18n.forRent}
                />
            </Grid2>
        </Grid2>
    )

    if (noLightCard) {
        return editMode ? editableContent() : content()
    }

    return (
        <LightCard
            container
            direction='column'
            justifyContent='space-between'
            className='clickable'
            sx={{ marginBottom: '20px', ...sx }}
        >
            {editMode ? editableContent() : content()}
        </LightCard>
    )
}

CounterCardDecla.propTypes = {
    counter: instanceOf(DtoVariousMateriel).isRequired,
    noLightCard: PropTypes.bool,
    startEditMode: PropTypes.bool,
    sx: PropTypes.shape({}),
}

export default CounterCardDecla
