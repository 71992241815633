module.exports = {
    ACTU_TO_HIDE: 'ACTUS_TO_HIDE',
    LAST_TIME_HOME: 'LAST_TIME_HOME',
    MEDEAU_TOKEN: 'medeau-token',
    MEDEAU_LOGIN: 'medeau-login',
    PATH: 'path',
    RECEIVE_ALL_USERS_LIGHT: 'RECEIVE_ALL_USERS_LIGHT',
    CREDENTIALS: 'CREDENTIALS',
    // DEFAULT_PATH: 'psp.integration.aquasys.fr',
    // CONTENT_PATH: 'https://psp.integration.aquasys.fr/contents/',
    // CMS_PATH: 'https://psp.integration.aquasys.fr/contents/CMS/',
    MAP_OPTION: 'MAP_OPTION',
    FULL_RESET: 'FULL_RESET',
    MODULE: 'PORTAIL',
    LOGIN: 'LOGIN',
    RECEIVE_DATE_VALID_CGU: 'RECEIVE_DATE_VALID_CGU',
    RECEIVE_APPLICATION_SETTINGS: 'RECEIVE_APPLICATION_SETTINGS',
    TOKEN_MEDEAU_LOGIN:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MDE1ODcyMzc3OTgsImxvZ2luIjoibWVkZWF1IiwiY29ubmVjdGlvbiI6IjIwMjAtMTAtMDEgMTE6MjA6MzciLCJsaWNlbnNlIjpbIlNJRyIsIlNJQURNIiwiU0lQIiwiU0lIIiwiU0lNQVQiLCJTSVEiLCJTSUVTIl19.MtQxgmnsWfRz1iXLj-0TR7f3SKz3YfQO2K-AUgHA8jU',
}
