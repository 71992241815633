/* eslint-disable no-process-env */
// Choisir une des deux usedUrl ci dessous

// A utiliser pour travailler en local (NE PAS COMMIT !!!)
// const urlApp = 'https://recette.manager.medeau.myliaq.fr/'
// const urlApp = 'https://demo.iryqua.fr/'

// A utiliser pour le serveur
const urlApp = (window.location.href || document.URL).split('#')[0]

// ////////////////////////////////////////////////////////////
const pathFormat = urlApp.endsWith('?') ? urlApp.slice(0, -1) : urlApp
const httpsPath = `${pathFormat.startsWith('https://') ? '' : 'https://'}${pathFormat}${pathFormat.endsWith('/') ? '' : '/'}`
const path = `${httpsPath}api/`
const CONTENT_PATH = `${httpsPath}contents/`
const CMS_PATH = `${CONTENT_PATH}CMS/`

export { path, CONTENT_PATH, CMS_PATH }