'use strict'

import { FULL_RESET } from '../../../offline/constants/HomeConstants'
import { RECEIVE_TANKS_TYPES } from '../../agri/constants/AgriConstants'
import DtoAgriTanksType from '../../agri/dto/DtoAgriTanksType'
import {
    RECEIVE_CULTURES,
    RECEIVE_CULTURES_FAMILIES,
    RECEIVE_CONTRIBUTOR,
    RECEIVE_CONTRIBUTORS,
    RECEIVE_USAGES,
    RECEIVE_SANDRE_CODES,
    RECEIVE_MANAGEMENT_UNITS,
    RECEIVE_ALL_WATERSHEDS,
    RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS,
    RECEIVE_WATERTURNS_SLOTS,
    RECEIVE_WATERTURNS_RESTRICTIONS,
    RECEIVE_MODES_IRRIGATIONS,
    RECEIVE_DROUGHT_SECTORS_RESTRICTIONS,
} from '../constants/ReferencialConstants'
import DtoCulture from '../dto/DtoCulture'
import DtoCulturesFamily from '../dto/DtoCulturesFamily'
import DtoManagementUnit from '../dto/DtoManagementUnit'
import DtoManagementUnitRestriction from '../dto/DtoManagementUnitRestriction'
import DtoSandreCode from '../dto/DtoSandreCode'
import DtoWaterTurnsSlot from '../dto/DtoWaterTurnsSlot'
import DtoWaterTurnRestriction from '../dto/DtoWaterTurnRestriction'
import DtoDroughtSectorRestriction from '../dto/DtoDroughtSectorRestriction'

export const store = {
    agriTanksTypes: [],
    codesSandre: [],
    contributor: {},
    contributors: [],
    cultures: [],
    culturesFamilies: [],
    droughtSectorsRestrictions: [],
    managementUnits: [],
    managementUnitsRestrictions: [],
    modesIrrigations: [],
    usagesCategories: [],
    watersheds: [],
    waterTurnsRestrictions: [],
    waterTurnsSlots: [],
}

export function ReferencialReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS:
            return {
                ...state,
                managementUnitsRestrictions: action.managementUnitsRestrictions.map((d) => new DtoManagementUnitRestriction(d)),
            }
        case RECEIVE_ALL_WATERSHEDS:
            return {
                ...state,
                watersheds: action.watersheds || [],
            }
        case RECEIVE_TANKS_TYPES:
            return {
                ...state,
                agriTanksTypes: action.tanksTypes.map(t => new DtoAgriTanksType(t)),
            }
        case RECEIVE_CULTURES:
            return {
                ...state,
                cultures: action.cultures.map((c) => new DtoCulture(c)),
            }
        case RECEIVE_CULTURES_FAMILIES:
            return {
                ...state,
                culturesFamilies: action.culturesFamilies.map((cF) => new DtoCulturesFamily(cF)),
            }
        case RECEIVE_CONTRIBUTOR:
            return {
                ...state,
                contributor: action.contributor,
            }
        case RECEIVE_CONTRIBUTORS:
            return {
                ...state,
                contributors: action.contributors,
            }
        case RECEIVE_USAGES:
            return {
                ...state,
                usagesCategories: action.usages,
            }
        case RECEIVE_SANDRE_CODES:
            return {
                ...state,
                codesSandre: action.codesSandre.map((c) => new DtoSandreCode(c)),
            }
        case RECEIVE_MANAGEMENT_UNITS:
            return {
                ...state,
                managementUnits: action.managementUnits.map((u) => new DtoManagementUnit(u)),
            }
        case RECEIVE_WATERTURNS_RESTRICTIONS:
            return {
                ...state,
                waterTurnsRestrictions: action.restrictions.map((r) => new DtoWaterTurnRestriction(r)),
            }
        case RECEIVE_WATERTURNS_SLOTS:
            return {
                ...state,
                waterTurnsSlots: action.waterTurnsSlots.map(d => new DtoWaterTurnsSlot(d)),
            }
        case RECEIVE_MODES_IRRIGATIONS:
            return {
                ...state,
                modesIrrigations: action.modesIrrigations,
            }
        case RECEIVE_DROUGHT_SECTORS_RESTRICTIONS:
            return {
                ...state,
                droughtSectorsRestrictions: action.droughtSectorsRestrictions.map((d) => new DtoDroughtSectorRestriction(d)),
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
