import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid2, useTheme } from '@mui/material'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoIntervenant from '../referencials/dto/DtoIntervenant'
import { formatPhone, formatSiret } from '../../../utils/StringUtil'
import ContactAction from '../contact/actions/ContactAction'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { MainButton } from '../components/styled/buttons'
import { hasValue } from '../../../utils/NumberUtil'
import { darkTextColor, textColor } from '../components/styled/theme'
import { compact } from 'lodash'
import { orderPointsList } from '../../../utils/ObjectUtils'
import { getI18nTitleDataLength } from '../../../utils/StringUtil'
import LoadingCard from '../components/cards/LoadingCard'
import { Circle, FolderOutlined } from '@mui/icons-material'
import { getStateLabel } from '../../../utils/AgriUtils'
import { LightCard } from '../components/styled/grid'
import { push } from 'connected-react-router'
import Table from '../components/Table'
import { POINT_STATUS } from '../referencials/installations/constants/InstallationsConstants'
import useProgressDispatch from '../../../utils/customHooks/useProgressDispatch'
import DeclarationAction from '../enquete/actions/DeclarationAction'
import ExploitationAction from './actions/ExploitationAction'

const MyExploitationDesktop = () => {
    const {
        contacts,
        exploitation,
        installations,
        contributors,
        citiesIndex,
        accountUser,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        exploitation: store.ExploitationReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        contributors: store.ReferencialReducer.contributors,
        citiesIndex: store.CityReducer.citiesIndex,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const dispatch = useDispatch()

    const theme = useTheme()

    const { isLoaded } = useProgressDispatch(() => {
        const promiseDeclarationByExploitationId = hasValue(exploitation?.idExploitation ? [
            dispatch(DeclarationAction.fetchDeclarationByExploitationId(exploitation.idExploitation))
        ] : [
            dispatch(ExploitationAction.fetchExploitation()).then(res => {
                dispatch(DeclarationAction.fetchDeclarationByExploitationId(res.idExploitation))
            })
        ])

        const promiseInstallationByExploitationId = hasValue(exploitation?.idExploitation ? [
            dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation))
        ] : [
            dispatch(ExploitationAction.fetchExploitation()).then(res => {
                dispatch(InstallationsAction.fetchInstallationsByExploitationId(res.idExploitation))
            })
        ])

        const promiseContacts = !contacts.length ? [
            dispatch(ContactAction.fetchContacts())
        ] : []

        const promiseContributors = !contributors.length ? [
            dispatch(ReferencialAction.fetchContributors())
        ] : []

        return [
            ...promiseDeclarationByExploitationId,
            ...promiseInstallationByExploitationId,
            ...promiseContacts,
            ...promiseContributors
        ]
    })

    const points = useMemo(() => (
        orderPointsList(compact(exploitation?.link_samplings?.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                return ({
                    ...point,
                    cityName: city.name,
                    stateCode: linkPoint.stateCode,
                    stateLabel: getStateLabel(linkPoint.stateCode),
                })
            }
            return null
        })))
    ), [exploitation, installations, citiesIndex])

    /* const contactsExploitation = useMemo(() => (
        exploitation.link_contributors.map((c) => {
            const contributorFind = contacts.find(
                (contact) => contact.id === c.contactCode,
            )
            if (contributorFind) {
                return {
                        ...contributorFind,
                        contributorType: c.contributorType,
                    }
            }
            return null
        })
    ), [exploitation, contacts]) */

    const preleveur = useMemo(() => (
        contributors.find((c) => c.id === exploitation.operatorCode) || new DtoIntervenant({})
    ), [contributors, exploitation])

    if (!isLoaded || Object.keys(citiesIndex).length === 0) {
        return <LoadingCard />
    }

    const getStatusColor = (point) => (
        POINT_STATUS.find(status => status.code === point.stateCode)?.color
    )

    const getRows = () => (
        points.map((point) => ({
            [i18n.pointName]: { value: point.name },
            [i18n.codification]: { value: point.code },
            [i18n.location]: { value: `${point?.cityName ? `${point.cityName}, ` : ''}${`${point.townCode}` ?? ''}` },
            [i18n.status]: { value: <Circle sx={{ color: getStatusColor(point), fontSize: '20px' }}/> },
            onClickValue: point
        }))
    )

    return (
        <Grid2 container size={12} direction={'column'} rowSpacing={2} sx={{ color: textColor }}>
            <Grid2 container alignItems='center' flexWrap='nowrap' justifyContent='space-between' >
                <Grid2 sx={{ fontSize: '22px', lineHeight: '28px' }}>{i18n.folder}</Grid2>
                {(accountUser.isAdmin === '1' || accountUser.metadata === '1') && (
                    <MainButton
                        noFullWidth
                        onClick={() => dispatch(push('/admin'))}
                        startIcon={<FolderOutlined sx={{ fontSize: '16px' }} />}
                        sx={{
                            margin: '0',
                            minHeight: '28px',
                            width: 'auto',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                    >
                        {i18n.selectFolder}
                    </MainButton>
                )}
            </Grid2>
            {hasValue(exploitation?.idExploitation) && (
                <Grid2 container rowSpacing={3}>
                    <Grid2 container size={6} >
                        <LightCard>
                            <Grid2
                                size={12}
                                sx={{
                                    fontSize: '22px',
                                    lineHeight: '28px',
                                    color: darkTextColor,
                                    paddingBottom: '16px'
                                }}
                            >
                                {i18n.samplerInfos}
                            </Grid2>
                            <Grid2
                                size={12}
                                container
                                alignItems='center'
                                justifyContent='space-between'
                                rowGap='8px'
                                fontSize={14}
                            >
                                <Grid2 size={3.5}>{i18n.id}</Grid2>
                                <Grid2 size={8.5} sx={{ fontWeight: 'medium' }}>{preleveur.internalIdentifier || preleveur.name}</Grid2>
                                <Grid2 size={3.5}>{i18n.siret}</Grid2>
                                <Grid2 size={8.5} sx={{ fontWeight: 'medium' }}>{formatSiret(preleveur.siret)}</Grid2>
                                <Grid2 size={3.5}>{i18n.address}</Grid2>
                                <Grid2 size={8.5} sx={{ fontWeight: 'medium' }}>{`${preleveur?.road ?? ''} ${preleveur?.addressComplement ?? ''}, ${preleveur?.postalCode ?? ''} `}</Grid2>{/* ${city?.name ?? ''} */}
                                <Grid2 size={3.5}>{i18n.courriel}</Grid2>
                                <Grid2 size={8.5} sx={{ fontWeight: 'medium' }}>{preleveur.email}</Grid2>
                                <Grid2 size={3.5}>{i18n.phoneFixe}</Grid2>
                                <Grid2 size={8.5} sx={{ fontWeight: 'medium' }}>{formatPhone(preleveur.phoneTel)}</Grid2>
                                <Grid2 size={3.5}>{i18n.phoneMobile}</Grid2>
                                <Grid2 size={8.5} sx={{ fontWeight: 'medium' }}>{formatPhone(preleveur.mobile)}</Grid2>
                            </Grid2>
                        </LightCard>
                    </Grid2>
                    <Grid2 container size={12} sx={{ overflow: 'hidden' }}>
                        {points.length > 0 && (
                            <LightCard
                                container
                                size={12}
                                alignContent='flex-start'
                                sx={{
                                    height: '100%',
                                    overflowY: 'auto',
                                }}
                            >
                                <Grid2
                                    size={12}
                                    sx={{
                                        fontSize: '22px',
                                        lineHeight: '28px',
                                        color: textColor,
                                    }}
                                >
                                    {`${points.length} ${getI18nTitleDataLength(i18n.pointPrelevement, i18n.pointsPrelevement, points.length)}`}
                                </Grid2>
                                <Grid2
                                    container
                                    size={12}
                                    sx={{ paddingTop: theme.spacing(3) }}
                                >
                                    <Table
                                        rowClickable
                                        onClickRow={(row) => {
                                            dispatch(push(`/dossier/point/${row.onClickValue.id}`))
                                        }}
                                        rows={getRows()}
                                        headers={[[
                                            { value: i18n.pointName, colSpan: '1' },
                                            { value: i18n.codification, colSpan: '1' },
                                            { value: i18n.location, colSpan: '1' },
                                            { value: i18n.status, colSpan: '1' },
                                        ]]}
                                        headersLabel={[
                                            i18n.pointName,
                                            i18n.codification,
                                            i18n.location,
                                            i18n.status,
                                        ]}
                                    />
                                </Grid2>
                            </LightCard>
                        )}
                    </Grid2>
                </Grid2>
            )}
        </Grid2>
    )
}

export default MyExploitationDesktop