import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import { Grid2, Tooltip } from '@mui/material'
import { compact, sortBy } from 'lodash'
import { MainButton } from '../online/components/styled/buttons'
import { InputRow } from '../online/components/styled/inputs'
import { CMS_PATH } from '../../conf/basepath'
import DtoCMSEvent from '../online/cms/dto/DtoCMSEvent'
import ToastrAction from '../online/components/toasters/ToastrAction'
import HomeAction from './actions/HomeAction'
import { getSetting, getSettingInt } from '../../utils/FormUtils'
import CmsAction from '../online/cms/actions/CmsAction'
import LogAction from '../../utils/log/actions/LogAction'
import aquasysLogoPath from '../../ressources/pictures/favicon.png'
import { textColor, veryLightColor, mainColor } from '../online/components/styled/theme'
import { useState, createRef, forwardRef } from 'react'
import { ArrowBack } from '@mui/icons-material'
import ModalUpdatePasswordDesktop from '../online/account/components/ModalUpdatePasswordDesktop'
import { arrayOf } from '../../utils/StoreUtils'

const CodeInput = forwardRef(({ onValueChange = () => {} }, ref) => {
    const [value, setValue] = useState('')
    const handleChange = (event) => {
        const inputValue = event.target.value
        if ((inputValue >= 0 && inputValue <= 9) || !inputValue) {
            setValue(inputValue)
            onValueChange(inputValue)
        }
    }


    return (
        <InputRow type='number' value={value} onChange={handleChange} maxLength='1' inputProps={{ min: 0, style: { textAlign: 'center' }, ref }}
            sx={{
                maxWidth: '56px',
                'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
                'input[type=number]': {
                    MozAppearance: 'textfield',
                },
            }}
        />
    )
})

CodeInput.propTypes = {
    onValueChange: PropTypes.func,
}

const PinCode = ({ setCode }) => {
    const [pinValue, setPinValue] = useState(['', '', '', ''])
    const inputRefs = Array.from({ length: 4 }, () => createRef())

    const handleValueChange = (input, value) => {
        setPinValue((prev) => {
            if (!value) {
                prev[input] = ''
                return prev
            }
            prev[input] = value
            return prev
        })

        if (input < 3 && value) {
            inputRefs[input + 1].current.focus()
        }

        setCode(pinValue.join(''))
    }


    return (
        <Grid2 container size={12} sx={{ width: '60%', gap: '8px' }} justifyContent='center'>
            <Grid2 container size={2.5} justifyContent='center'>
                <CodeInput ref={inputRefs[0]} onValueChange={(value) => handleValueChange(0, value)} />
            </Grid2>
            <Grid2 container size={2.5} justifyContent='center'>
                <CodeInput ref={inputRefs[1]} onValueChange={(value) => handleValueChange(1, value)}/>
            </Grid2>
            <Grid2 container size={2.5} justifyContent='center'>
                <CodeInput ref={inputRefs[2]} onValueChange={(value) => handleValueChange(2, value)}/>
            </Grid2>
            <Grid2 container size={2.5} justifyContent='center'>
                <CodeInput ref={inputRefs[3]} onValueChange={(value) => handleValueChange(3, value)}/>
            </Grid2>
        </Grid2>
    )
}

PinCode.propTypes = {
    setCode: PropTypes.func,
}

class Password extends Component {
    constructor(props) {
        super(props)
        this.state = {
            identifiant: '',
            openModal: false,
            images: JSON.parse(localStorage.getItem('IRYQUA_images')) || [],
            resetCode: null,
            settingsLoaded: false,
            newMdp: null,
            mdpConfirmation: null,
        }
    }

    setCode = (code) => {
        this.setState({ resetCode: code })
    }

    componentDidMount() {
        const { applicationSettings, cmsEvents } = this.props
        if (!cmsEvents.length) {
            this.props.fetchCMSEvents().then(() => {
                if (!applicationSettings.length) {
                    this.props.fetchApplicationSettings().then(() => this.setLogos())
                } else {
                    this.setLogos()
                }
            })
        } else {
            this.setLogos()
        }
    }

    setLogos = () => {
        const { applicationSettings, cmsEvents } = this.props
        const applicationName = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
        if (applicationName.value) {
            document.title = applicationName.value
            $('#favicon').attr('href', getSetting(applicationSettings, 'applicationFavicon') || aquasysLogoPath)
        }
        const idCategPartenaires = getSettingInt(applicationSettings, 'iryquaCategorieCmsPartenaires')
        const partenaires = cmsEvents.filter((c) => c.idCategory === idCategPartenaires)
        const images = compact(sortBy(partenaires, 'subtitle').map((c) => {
            if (c?.document[0]) {
                return {
                    link: c.link,
                    docName: CMS_PATH + c.document[0].name,
                }
            }
            return null
        }))
        localStorage.removeItem('IRYQUA_images')
        localStorage.setItem('IRYQUA_images', JSON.stringify(images))
        this.setState({
            settingsLoaded: true,
            images,
        })
    }

    handleChangeValue = (value) => {
        this.setState({ identifiant: value })
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onSubmit = () => {
        const { identifiant } = this.state
        if (identifiant) {
            this.props.logJournal(identifiant, `Demande de mdp oublié pour l'identifiant : ${identifiant}`, 'FORGET_PASSWORD')
            this.props.resetPassword(identifiant, false).then(() => {
                ToastrAction.info(i18n.sendEmail)
            })
        } else {
            ToastrAction.error(i18n.fillAllFields, true)
        }
    }

    openWebSite = (link) => {
        if (link) {
            window.open(link, '_blank')
        }
    }

    onValidateResetCode = (login, resetCode) => {
        if (login) {
            if (resetCode) {
                this.props.verifyResetCode(login, resetCode.toString()).then(valide => {
                    if (valide) {
                        this.setState({ openModal: true })
                    }
                })
            } else {
                ToastrAction.error('Veuillez renseigner le code qui vous a été envoyé par mail', true)
            }
        } else {
            ToastrAction.error('Veuillez renseigner votre identifiant', true)
        }
    }

    toggleModalPassword = () => {
        this.setState(({ openModal }) => ({ openModal: !openModal }))
    }

    onUpdatePassword = () => {
        const { newMdp, mdpConfirmation, resetCode } = this.state
        const { applicationSettings } = this.props
        if (
            newMdp !== null &&
            newMdp.length &&
            mdpConfirmation !== null &&
            mdpConfirmation.length
        ) {
            if (newMdp === mdpConfirmation) {
                const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                if (regex.test(newMdp)) {
                    this.props.updatePassword(newMdp, resetCode).then(() => {
                        ToastrAction.success(i18n.passwordUpdatedSuccessfully)
                        this.props.push('/login')
                    })
                } else {
                    ToastrAction.error(regexHelp, true)
                }
            } else {
                ToastrAction.error('Les mots de passe ne sont pas identiques', true)
            }
        }
    }

    getPanel = () => {
        const { identifiant, resetCode } = this.state
        const back = () => {
            history.back()
            window.scrollTo(0, 0)
        }
        return (
            <Grid2
                container
                sx={{ width: '45vw' }}
            >
                <Grid2 container justifyContent='flex-end' size={2} className='clickable' onClick={ back }>
                    <Tooltip title={i18n.back}>
                        <ArrowBack sx={{ paddingRight: '1vw', height: 25, color: mainColor }} />
                    </Tooltip>
                </Grid2>
                <Grid2
                    container
                    size={8}
                    direction='column'
                    justifyItems='center'
                    alignItems= 'center'
                    gap='1rem'
                >
                    <Grid2 sx={{ width: '100%', fontSize: '22px', lineHeight: '28px', color: textColor }} >
                        {i18n.resetYourPassword}
                    </Grid2>
                    <Grid2 sx={{ width: '100%', fontSize: '12px', lineHeight: '1.5', color: textColor }}>
                        {i18n.enterIdDescription}
                    </Grid2 >
                    <Grid2 sx={{ width: '100%' }}>
                        <InputRow
                            id='password'
                            label={i18n.id}
                            type='text'
                            value={identifiant}
                            onChange={(event) => this.handleChangeValue(event.target.value)}
                            variant='outlined'
                            required
                        />
                    </Grid2>
                    <Grid2 sx={{ width: '60%' }}>
                        <MainButton onClick={this.onSubmit} little sx={{ fontSize: '14px' }} >
                            {i18n.getCode}
                        </MainButton>
                    </Grid2>
                    <Grid2 container alignItems='center'>
                        <Grid2 width='100%' size={5}>
                            <hr/>
                        </Grid2>
                        <Grid2 size={2} textAlign='center'>
                            { i18n.or }
                        </Grid2>
                        <Grid2 size={5}>
                            <hr/>
                        </Grid2>
                    </Grid2>
                    <Grid2 sx={{ width: '100%', fontSize: '22px', lineHeight: '28px', color: textColor }}>
                        {i18n.alreadyHaveCodeTitle}
                    </Grid2>
                    <Grid2 sx={{ width: '100%', fontSize: '12px', lineHeight: '1.5', color: textColor }}>
                        {i18n.alreadyHaveCodeDescription}
                    </Grid2>
                    <PinCode setCode={this.setCode} />
                    <Grid2 sx={{ width: '60%' }}>
                        <MainButton onClick={() => this.onValidateResetCode(identifiant, resetCode)} little sx={{ fontSize: '14px' }} >
                            {i18n.resetThePassword}
                        </MainButton>
                    </Grid2>
                </Grid2>
                <Grid2 size={2}/>
            </Grid2>

        )
    }


    render() {
        const { settingsLoaded, openModal, images } = this.state
        const { applicationSettings } = this.props
        const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription') || {}
        const modal = (<>{settingsLoaded && openModal && (
            <ModalUpdatePasswordDesktop
                title={i18n.changePassword}
                label={regexHelp.value}
                open={openModal}
                toggleModal={this.toggleModalPassword}
                handleChangeNewMdp={(e) => this.setState({ newMdp: e.target.value })}
                handleChangeConfirmation={(e) => this.setState({ mdpConfirmation: e.target.value })}
                onSavePassword={this.onUpdatePassword}
                firstLogin
            />
        )}</>)
        return (
            <Grid2
                container
                sx={{
                    backgroundColor: veryLightColor,
                    position: 'fixed',
                    zIndex: '2',
                    height: '100vh',
                    width: '100vw',
                    padding: '5vh 10px 5vh 5vw',
                }}
            >
                <Grid2 container size={7}
                    direction = 'column'
                    justifyContent='center'
                    alignItems='center'
                    borderRadius= '20px'
                    backgroundColor= '#FFF'
                >
                    {this.getPanel()}
                </Grid2>
                <Grid2 container size={5}
                    justifyContent='space-around'
                    alignItems='center'
                    sx={{
                        overflowY: 'auto',
                        maxHeight: '100%',
                    }}
                    gap='5vh'
                >
                    {images.map((i, index) => (
                        <Grid2 container size={12} key={index} justifyContent='center'>
                            <img
                                src={i.docName}
                                alt={i.link}
                                className='clickable'
                                onClick={() => this.openWebSite(i.link)}
                                style={{
                                    height: '100%',
                                    width: '50%',
                                }}
                            />
                        </Grid2>
                    )
                    )}
                </Grid2>
                { modal }
            </Grid2>
        )
    }
}

const mapDispatchToProps = {
    resetPassword: HomeAction.resetPassword,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    verifyResetCode: HomeAction.verifyResetCode,
    updatePassword: HomeAction.updatePassword,
    fetchCMSEvents: CmsAction.fetchCMSEvents,
    logJournal: LogAction.logJournal,
    push,
}

const mapStateToProps = (store) => {
    return {
        applicationSettings: store.HomeReducer.applicationSettings,
        cmsEvents: store.CmsReducer.cmsEvents,
    }
}

Password.propTypes = {
    updatePassword: PropTypes.func,
    resetPassword: PropTypes.func,
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    fetchApplicationSettings: PropTypes.func,
    verifyResetCode: PropTypes.func,
    push: PropTypes.func,
    fetchCMSEvents: PropTypes.func,
    logJournal: PropTypes.func,
    cmsEvents: arrayOf(DtoCMSEvent),
}

export default connect(mapStateToProps, mapDispatchToProps)(Password)
