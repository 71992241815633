import {
    RECEIVE_CMS_EVENTS,
} from '../constants/CmsConstants'
import DtoCMSEvent from '../dto/DtoCMSEvent'

export function CmsReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_CMS_EVENTS:
            return { ...state, cmsEvents: action.cmsEvents.map((n) => new DtoCMSEvent(n)) }
        default:
            return state
    }
}

export const store = {
    cmsEvents: [],
}
