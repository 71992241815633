/* eslint-disable max-nested-callbacks */
import React, { useMemo, useState } from 'react'
import { DialogContentMUIDesktop, DialogMUI, DialogTitleMUIv2 } from './styled/dialog'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Checkbox, Chip, DialogActions, FormControl, FormControlLabel, Grid2, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material'
import { darkTextColor, lightGrey, mainColor, mainRed, textColor, veryLightGrey } from './styled/theme'
import { MainButton, TextButton } from './styled/buttons'
import moment from 'moment'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import PumpCardDecla from './cards/PumpCardDecla'
import DtoVariousMateriel from '../referencials/materiels/dto/DtoVariousMateriel'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'
import { LightCard } from './styled/grid'
import { InputRow } from './styled/inputs'
import { hasValue } from '../../../utils/NumberUtil'
import useSandreList from '../../../utils/customHooks/useSandreList'
import { MAT_CATEGORY } from '../agri/constants/AgriConstants'
import { compact } from 'lodash'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import DtoVariousMatSituation from '../referencials/materiels/dto/DtoVariousMatSituation'
import LoadingCard from './cards/LoadingCard'
import DtoVariousMatPump from '../referencials/materiels/dto/DtoVariousMatPump'
import DtoVariousMatCounter from '../referencials/materiels/dto/DtoVariousMatCounter'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import ExploitationAction from '../exploitation/actions/ExploitationAction'

const STEPS = {
    PUMP_STEP: 1,
    COUNTER_STEP: 2
}

const ModalCreateCounter = ({ codeInstallation, open, setOpen, idInstallation }) => {
    const {
        declaration,
        exploitation,
        variousMateriels,
        variousMatTypes,
    } = useSelector(store => ({
        declaration: store.DeclarationReducer.declaration,
        exploitation: store.ExploitationReducer.exploitation,
        variousMateriels: store.MaterielReducer.variousMateriels,
        variousMatTypes: store.MaterielReducer.variousMatTypes,
    }), shallowEqual)

    const dispatch = useDispatch()

    const allPumps = useMemo(() => variousMateriels.filter((m) => m.pump), [variousMateriels])
    const allCounters = useMemo(() => variousMateriels.filter((m) => m.counter), [variousMateriels])

    const prefixCodificationCounter = useApplicationSetting('prefixCodificationCounter') || ''
    const suffixCodificationCounter = useApplicationSetting('suffixCodificationCounter') || ''
    const minLengthCodificationCounter = useApplicationSetting('minLengthCodificationCounter') || 0
    const newRefCounter = useMemo(() => {
        const maxRefCounter = Math.max(...allCounters.map((c) => {
            if (c?.reference?.startsWith(prefixCodificationCounter) && c?.reference?.endsWith(suffixCodificationCounter)) {
                const str = c?.reference?.replace(prefixCodificationCounter, '') || ''
                return parseInt(str.slice(0, str.length - suffixCodificationCounter.length)) || 0
            }
            return 0
        })) + 1
        const newId = maxRefCounter.toString().length >= minLengthCodificationCounter ? maxRefCounter : `${'0'.repeat(minLengthCodificationCounter - maxRefCounter.toString().length)}${maxRefCounter}`
        return `${prefixCodificationCounter}${newId}${suffixCodificationCounter}`
    }, [prefixCodificationCounter, suffixCodificationCounter, minLengthCodificationCounter, allCounters])

    const prefixCodificationPump = useApplicationSetting('prefixCodificationPump') || ''
    const suffixCodificationPump = useApplicationSetting('suffixCodificationPump') || ''
    const minLengthCodificationPump = useApplicationSetting('minLengthCodificationPump') || 0
    const newRefPump = useMemo(() => {
        const maxRefPump = Math.max(...allPumps.map((p) => {
            if (p?.reference?.startsWith(prefixCodificationPump) && p?.reference?.endsWith(suffixCodificationPump)) {
                const str = p?.reference?.replace(prefixCodificationPump, '') || ''
                return parseInt(str.slice(0, str.length - suffixCodificationPump.length)) || 0
            }
            return 0
        })) + 1
        const newId = maxRefPump.toString().length >= minLengthCodificationPump ? maxRefPump : `${'0'.repeat(minLengthCodificationPump - maxRefPump.toString().length)}${maxRefPump}`
        return `${prefixCodificationPump}${newId}${suffixCodificationPump}`
    }, [prefixCodificationPump, suffixCodificationPump, minLengthCodificationPump, allPumps])

    const [currentStep, setCurrentStep] = useState(1)
    const [usingExistingPump, setUsingExistingPump] = useState(false)
    const [selectedPump, setSelectedPump] = useState(new DtoVariousMateriel({ reference: newRefPump, materielType: 1 }))
    const [newCounter, setNewCounter] = useState(new DtoVariousMateriel({ reference: newRefCounter }))
    const [isLoading, setIsLoading] = useState(false)

    const counterCountingTypes = useSandreList('COMPTAGES.TYPE')

    const pumpCategories = useSandreList('MAT.MOBILITE')
    const pumpTypes = useSandreList('POMPES.TYPE')
    const getLinkPumpInstallation = useMemo(() => (
        compact(declaration?.link_declarationInstallation?.map(instal => instal.idInstallation === Number(idInstallation) ?
            compact(instal?.link_equipments.map(equip => !equip.idMatAttachment && equip.mode !== 'd' && equip.idMat === selectedPump.id ? equip : null))
            :
            null
        ))
    ), [declaration, selectedPump])

    const buttonDisabled = useMemo(() => {
        switch (currentStep) {
            case STEPS.PUMP_STEP : default :
                if (usingExistingPump) {
                    return !selectedPump.id
                }
                return !selectedPump
            case STEPS.COUNTER_STEP :
                return (!newCounter.materielType || !newCounter?.counter?.brand || !newCounter.serialNumber || !newCounter?.counter?.readingCoefficient || !newCounter?.counter?.installationDate || !(newCounter?.counter?.counterType >= 0))
        }
    }, [selectedPump, usingExistingPump, currentStep, newCounter])

    const saveSitus = (situs) => {
        dispatch(MaterielAction.createAllVariousMaterielSituation(situs)).then(() =>
            dispatch(MaterielAction.fetchVariousMatSituations()).then(() =>
                dispatch(ExploitationAction.fetchExploitation()).then(() =>
                    dispatch(InstallationsAction.fetchInstallation(idInstallation)).then(() =>
                        setOpen(!open)
                    )
                )
            )
        )
    }

    const createMats = () => {
        setIsLoading(true)
        if (!selectedPump.id) {
            dispatch(MaterielAction.createVariousMateriel(new DtoVariousMateriel({ ...selectedPump, pump: new DtoVariousMatPump({}), administrator: exploitation.operatorCode })))
                .then((pumpRes) => {
                    dispatch(MaterielAction.createVariousMateriel(new DtoVariousMateriel({ counter: new DtoVariousMatCounter({}), ...newCounter, administrator: exploitation.operatorCode })))
                        .then((counterRes) => {
                            const pumpSitu = new DtoVariousMatSituation({
                                idVarious: pumpRes,
                                siteCode: idInstallation,
                                siteName: codeInstallation,
                                siteType: SITU_POINT_PUMP,
                            })
                            const counterSitu = new DtoVariousMatSituation({
                                idVarious: counterRes,
                                siteCode: pumpRes,
                                siteName: selectedPump.reference,
                                siteType: SITU_PUMP_COUNTER,
                            })
                            saveSitus([pumpSitu, counterSitu])
                        })
                })
        } else {
            dispatch(MaterielAction.createVariousMateriel(new DtoVariousMateriel({ counter: new DtoVariousMatCounter({}), ...newCounter, administrator: exploitation.operatorCode })))
                .then((counterRes) => {
                    const counterSitu = new DtoVariousMatSituation({
                        idVarious: counterRes,
                        siteCode: selectedPump.id,
                        siteName: selectedPump.reference,
                        siteType: SITU_PUMP_COUNTER,
                    })
                    saveSitus([counterSitu])
                })
        }
    }


    const pumps = variousMateriels.filter(mats => ((exploitation.link_materiel || [])
        .filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation && (m.situationEndDate ? m.situationEndDate > moment().valueOf() : true) && (m.situationDate ? m.situationDate < moment().valueOf() : true))
        .map(({ idVarious }) => idVarious) || [])
        .includes(mats.id)
    )


    return (
        <DialogMUI
            open={open}
            onClose={setOpen}
        >
            <DialogTitleMUIv2 onClick={setOpen}>
                {i18n.addCounter}
            </DialogTitleMUIv2>
            {isLoading ?
                <LoadingCard />
                :
                <DialogContentMUIDesktop
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Grid2 container size={12} spacing={2} >
                        <Grid2
                            container
                            size={12}
                            className={currentStep === STEPS.PUMP_STEP ? '' : 'clickable'}
                            onClick={currentStep === STEPS.PUMP_STEP ? () => {} : () => setCurrentStep(STEPS.PUMP_STEP)}
                            sx={{
                                borderBottom: currentStep === STEPS.PUMP_STEP ? '' :`1px solid ${veryLightGrey}`,
                                rowSpacing: 2,
                                paddingBottom: currentStep === STEPS.PUMP_STEP ? 0 : 2
                            }}
                        >
                            <Grid2 size={12} container alignItems='center' columnSpacing={1} >
                                <Chip sx={{ backgroundColor: mainColor, color: 'white' }} size='small' label={STEPS.PUMP_STEP}/>
                                <Grid2
                                    sx={{
                                        color: currentStep === STEPS.PUMP_STEP ? textColor : darkTextColor,
                                        fontSize: currentStep === STEPS.PUMP_STEP ? '22px' : '16px',
                                        lineHeight: currentStep === STEPS.PUMP_STEP ? '28px' : '20px',
                                        fontWeight: currentStep === STEPS.PUMP_STEP ? 400 : 500,
                                    }}
                                >
                                    {i18n.pumpingEquipment} {currentStep === STEPS.PUMP_STEP ? `(${currentStep}/2)` : null}
                                </Grid2>
                            </Grid2>
                            {currentStep === STEPS.PUMP_STEP ?
                                <Grid2 container size={12} spacing={1}>
                                    <Grid2 size={12}>{i18n.selectExistingPump}</Grid2>
                                    <Grid2 container size={12} >
                                        <RadioGroup
                                            row
                                            name= {i18n.selectExistingPump}
                                            onChange={(e) => setUsingExistingPump(Boolean(Number(e.target.value)))}
                                            sx={{ color: textColor }}
                                        >
                                            <FormControlLabel
                                                checked={usingExistingPump}
                                                value={1}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: textColor,
                                                            '&.Mui-checked': {
                                                                color: textColor,
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={i18n.yes}
                                            />
                                            <FormControlLabel
                                                checked={!usingExistingPump}
                                                value={0}
                                                control={
                                                    <Radio
                                                        onClick={() => setSelectedPump(new DtoVariousMateriel({ reference: newRefPump, materielType: 1 }))}
                                                        sx={{
                                                            color: textColor,
                                                            '&.Mui-checked': {
                                                                color: textColor,
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={i18n.no}
                                            />
                                        </RadioGroup>
                                    </Grid2>
                                    {usingExistingPump ?
                                        <Grid2 container size={12} spacing={2} sx={{ paddingBottom: 1 }}>
                                            {pumps.map((p) =>(
                                                <Grid2 className={selectedPump.id === p.id ? '' : 'clickable'} onClick={() => setSelectedPump(p)} >
                                                    <PumpCardDecla disabled pump={p} sx={{ marginBottom: 0, border: selectedPump.id === p.id ? `solid 1px ${darkTextColor}` : 'solid 1px white', borderRadius: 5 }} />
                                                </Grid2>
                                            ))}
                                        </Grid2>
                                        :
                                        <Grid2 container size={12} spacing={2}>
                                            <LightCard
                                                container
                                                direction='column'
                                                justifyContent='space-between'
                                            >
                                                <Grid2 container spacing={2} sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
                                                    <Grid2 container size={12} justifyContent='space-between' alignItems='center'>
                                                        <Grid2 sx={{ fontSize: '18px' }}><b>{i18n.pump}</b> {selectedPump?.reference}</Grid2>
                                                    </Grid2>
                                                    <InputRow
                                                        disabled
                                                        label={i18n.name}
                                                        value={selectedPump.name}
                                                        onChange={(e) => setSelectedPump(new DtoVariousMateriel({
                                                            ...selectedPump,
                                                            name: e.target.value
                                                        }))}
                                                    />
                                                    <FormControl disabled fullWidth>
                                                        <InputLabel id={i18n.type}>{i18n.type}</InputLabel>
                                                        <Select
                                                            labelId={i18n.type}
                                                            label={i18n.type}
                                                            value={selectedPump.pumpType ?? ''}
                                                            onChange={(e) => setSelectedPump(new DtoVariousMateriel({
                                                                ...selectedPump,
                                                                pump: {
                                                                    ...selectedPump.pump,
                                                                    pumpType: hasValue(e.target.value) ? Number(e.target.value) : '' }
                                                            }))}
                                                        >
                                                            {
                                                                pumpTypes.map(type => (
                                                                    <MenuItem
                                                                        value={type.code}
                                                                        style={{ maxWidth: '100%' }}
                                                                    >
                                                                        {type.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl disabled fullWidth>
                                                        <InputLabel id={i18n.categ}>{i18n.categ}</InputLabel>
                                                        <Select
                                                            labelId={i18n.categ}
                                                            label={i18n.categ}
                                                            value={selectedPump.mobilityCode ?? ''}
                                                            onChange={(e) => setSelectedPump(new DtoVariousMateriel({
                                                                ...selectedPump,
                                                                mobilityCode: hasValue(e.target.value) ? Number(e.target.value) : ''
                                                            }))}
                                                        >
                                                            {
                                                                pumpCategories.map(cat => (
                                                                    <MenuItem
                                                                        value={cat.code}
                                                                        style={{ maxWidth: '100%' }}
                                                                    >
                                                                        {cat.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl disabled fullWidth>
                                                        <InputLabel id={i18n.nature}>{i18n.nature}</InputLabel>
                                                        <Select
                                                            labelId={i18n.nature}
                                                            label={i18n.nature}
                                                            value={selectedPump.materielType ?? ''}
                                                            onChange={(e) => setSelectedPump(new DtoVariousMateriel({
                                                                ...selectedPump,
                                                                materielType: hasValue(e.target.value) ? Number(e.target.value) : ''
                                                            }))}
                                                        >
                                                            {
                                                                variousMatTypes.map(type => type.category === MAT_CATEGORY.PUMP && (
                                                                    <MenuItem
                                                                        value={type.materielType}
                                                                        style={{ maxWidth: '100%' }}
                                                                    >
                                                                        {type.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <Grid2 container size={12} spacing={1}>
                                                        <Grid2 size={6}>
                                                            <InputRow
                                                                type='date'
                                                                disabled
                                                                label={i18n.assignementDate}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                value={getLinkPumpInstallation?.startDate ? moment(getLinkPumpInstallation?.startDate).format('YYYY-MM-DD') : null}
                                                                onChange={(e) => setSelectedPump(new DtoVariousMateriel({
                                                                    ...selectedPump,
                                                                    startDate: e.target.value
                                                                }))}
                                                            />
                                                        </Grid2>
                                                        <Grid2 size={6}>
                                                            <InputRow
                                                                type='date'
                                                                disabled
                                                                label={i18n.decommisionDate}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                value={getLinkPumpInstallation?.endDate ? moment(getLinkPumpInstallation?.endDate).format('YYYY-MM-DD') : null}
                                                                onChange={(e) => setSelectedPump(new DtoVariousMateriel({
                                                                    ...selectedPump,
                                                                    startDate: e.target.value
                                                                }))}
                                                            />
                                                        </Grid2>
                                                    </Grid2>
                                                    <InputRow
                                                        type='number'
                                                        disabled
                                                        label={i18n.debitMax}
                                                        value={selectedPump.maxFlow}
                                                        onChange={(e) => setSelectedPump(new DtoVariousMateriel({
                                                            ...selectedPump,
                                                            pump: {
                                                                ...selectedPump.pump,
                                                                maxFlow: Number(e.target.value)
                                                            }
                                                        }))}
                                                    />
                                                    <InputRow
                                                        type='Number'
                                                        disabled
                                                        label={i18n.debitFonctionnement}
                                                        value={selectedPump.operatingFlow}
                                                        onChange={(e) => setSelectedPump(new DtoVariousMateriel({
                                                            ...selectedPump,
                                                            pump: {
                                                                ...selectedPump.pump,
                                                                operatingFlow: Number(e.target.value)
                                                            }
                                                        }))}
                                                    />
                                                    <FormControlLabel
                                                        disabled
                                                        control={
                                                            <Checkbox
                                                                value={selectedPump?.isShared || null}
                                                                checked={selectedPump.isShared}
                                                                onChange={() => setSelectedPump(new DtoVariousMateriel({
                                                                    ...selectedPump,
                                                                    isShared: !selectedPump.isShared
                                                                }))}
                                                            />
                                                        }
                                                        label={i18n.sharedEquipment}
                                                    />
                                                    <InputRow
                                                        disabled
                                                        label={i18n.comment}
                                                        value={selectedPump.comment}
                                                        multiline
                                                        rows={4}
                                                        onChange={(e) => setSelectedPump(new DtoVariousMateriel({
                                                            ...selectedPump,
                                                            comment: e.target.value ?? null
                                                        }))}
                                                    />
                                                </Grid2>
                                            </LightCard>
                                        </Grid2>
                                    }
                                </Grid2>
                                :
                                <Grid2 sx={{ color: lightGrey, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }}>{i18n.selectedPump} : {selectedPump.reference}</Grid2>
                            }
                        </Grid2>
                        {currentStep >= STEPS.COUNTER_STEP && <Grid2
                            container
                            size={12}
                            className={currentStep === STEPS.COUNTER_STEP ? '' : 'clickable'}
                            onClick={currentStep === STEPS.COUNTER_STEP ? () => {} : () => setCurrentStep(STEPS.COUNTER_STEP)}
                            sx={{
                                borderBottom: currentStep === STEPS.COUNTER_STEP ? '' :`1px solid ${veryLightGrey}`,
                                rowSpacing: 2,
                                paddingBottom: 2
                            }}
                        >
                            <Grid2 size={12} container alignItems='center' columnSpacing={1} >
                                <Chip sx={{ backgroundColor: mainColor, color: 'white' }} size='small' label={STEPS.COUNTER_STEP}/>
                                <Grid2
                                    sx={{
                                        color: currentStep === STEPS.COUNTER_STEP ? textColor : darkTextColor,
                                        fontSize: currentStep === STEPS.COUNTER_STEP ? '22px' : '16px',
                                        lineHeight: currentStep === STEPS.COUNTER_STEP ? '28px' : '20px',
                                        fontWeight: currentStep === STEPS.COUNTER_STEP ? 400 : 500,
                                    }}
                                >
                                    {i18n.countingDevice} {currentStep === STEPS.COUNTER_STEP ? `(${currentStep}/2)` : null}
                                </Grid2>
                            </Grid2>
                            <Grid2 container size={12} spacing={1}>
                                <Grid2 size={12}>{i18n.createNewCounter}</Grid2>
                                <Grid2 container size={12}>
                                    <LightCard
                                        container
                                        direction='column'
                                        justifyContent='space-between'
                                    >
                                        <Grid2 container size={12} spacing={2} sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
                                            <Grid2 container size={12} justifyContent='space-between' alignItems='center'>
                                                <Grid2 sx={{ fontSize: '18px' }}><b>{i18n.counter}</b> {newCounter?.reference}</Grid2>
                                                {buttonDisabled && <Grid2 size={12} color={mainRed}>{i18n.requiredField}</Grid2> }
                                            </Grid2>
                                            <InputRow
                                                label={i18n.name}
                                                value={newCounter.name}
                                                onChange={(e) => setNewCounter(new DtoVariousMateriel({
                                                    ...newCounter,
                                                    name: e.target.value
                                                }))}
                                                error={!newCounter.name}
                                            />
                                            <FormControl error={!newCounter.materielType} fullWidth>
                                                <InputLabel id={i18n.type}>{i18n.type}</InputLabel>
                                                <Select
                                                    labelId={i18n.type}
                                                    label={i18n.type}
                                                    value={newCounter.materielType ?? ''}
                                                    onChange={(e) => setNewCounter(new DtoVariousMateriel({
                                                        ...newCounter,
                                                        materielType: hasValue(e.target.value) ? Number(e.target.value) : ''
                                                    }))}
                                                >
                                                    {
                                                        variousMatTypes.map(type => type.category === MAT_CATEGORY.COUNTER && (
                                                            <MenuItem
                                                                value={type.materielType}
                                                                style={{ maxWidth: '100%' }}
                                                            >
                                                                {type.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            <FormControl error={!(newCounter?.counter?.counterType >= 0)} fullWidth>
                                                <InputLabel id={i18n.countingType}>{i18n.countingType}</InputLabel>
                                                <Select
                                                    labelId={i18n.countingType}
                                                    label={i18n.countingType}
                                                    value={newCounter?.counter?.counterType ?? ''}
                                                    onChange={(e) => setNewCounter(new DtoVariousMateriel({
                                                        ...newCounter,
                                                        counter: {
                                                            ...newCounter.counter,
                                                            counterType: hasValue(e.target.value) ? Number(e.target.value) : '' }
                                                    }))}
                                                >
                                                    {counterCountingTypes.map(type => (
                                                        <MenuItem
                                                            value={type.code}
                                                            style={{ maxWidth: '100%' }}
                                                        >
                                                            {type.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <InputRow
                                                label={i18n.marque}
                                                value={newCounter?.counter?.brand}
                                                onChange={(e) => setNewCounter(new DtoVariousMateriel({
                                                    ...newCounter,
                                                    counter: {
                                                        ...newCounter.counter,
                                                        brand: e.target.value
                                                    }
                                                }))}
                                                error={!newCounter?.counter?.brand}
                                            />
                                            <InputRow
                                                label={i18n.serialNumber}
                                                value={newCounter.serialNumber}
                                                onChange={(e) => setNewCounter(new DtoVariousMateriel({
                                                    ...newCounter,
                                                    serialNumber: e.target.value
                                                }))}
                                                error={!newCounter.serialNumber}
                                            />
                                            <InputRow
                                                type='Number'
                                                label={i18n.coefLecture}
                                                value={newCounter?.counter?.readingCoefficient}
                                                onChange={(e) => setNewCounter(new DtoVariousMateriel({
                                                    ...newCounter,
                                                    counter: {
                                                        ...newCounter.counter,
                                                        readingCoefficient: Number(e.target.value)
                                                    }
                                                }))}
                                                error={!newCounter?.counter?.readingCoefficient}
                                            />
                                            <Grid2 container size={12} spacing={2}>
                                                <Grid2 size={6}>
                                                    <InputRow
                                                        type='date'
                                                        label={i18n.installationDate}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={newCounter?.installationDate ? moment(newCounter?.installationDate).format('YYYY-MM-DD') : null}
                                                        onChange={(e) => setNewCounter(new DtoVariousMateriel({
                                                            ...newCounter,
                                                            counter: {
                                                                ...newCounter.counter,
                                                                installationDate: e.target.value ? moment(e.target.value, 'YYYY-MM-DD').valueOf() : null
                                                            }
                                                        }))}
                                                        error={!newCounter?.counter?.installationDate}
                                                    />
                                                </Grid2>
                                                <Grid2 size={6}>
                                                    <InputRow
                                                        type='date'
                                                        label={i18n.revisionDate}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={newCounter?.revisionDate ? moment(newCounter?.revisionDate).format('YYYY-MM-DD') : null}
                                                        onChange={(e) =>
                                                            setNewCounter(new DtoVariousMateriel({
                                                                ...newCounter,
                                                                counter: {
                                                                    ...newCounter.counter,
                                                                    revisionDate: e.target.value ? moment(e.target.value, 'YYYY-MM-DD').valueOf() : null
                                                                }
                                                            }))
                                                        }
                                                    />
                                                </Grid2>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            value={newCounter?.isShared || null}
                                                            checked={newCounter.isShared}
                                                            onChange={() => setNewCounter(new DtoVariousMateriel({
                                                                ...newCounter,
                                                                isShared: !newCounter.isShared
                                                            }))}
                                                        />
                                                    }
                                                    label={i18n.sharedEquipment}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            value={newCounter?.rent || null}
                                                            checked={newCounter.rent}
                                                            onChange={() => setNewCounter(new DtoVariousMateriel({
                                                                ...newCounter,
                                                                rent: !newCounter.rent
                                                            }))}
                                                        />
                                                    }
                                                    label={i18n.forRent}
                                                />
                                                <InputRow
                                                    label={i18n.comment}
                                                    value={newCounter.comment}
                                                    multiline
                                                    rows={4}
                                                    onChange={(e) => setNewCounter(new DtoVariousMateriel({
                                                        ...newCounter,
                                                        comment: e.target.value ?? null
                                                    }))}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </LightCard>
                                </Grid2>
                            </Grid2>
                        </Grid2>}
                    </Grid2>
                </DialogContentMUIDesktop>}
            <DialogActions sx={{ width: '100%', padding: 0 }}>
                <Grid2 container justifyContent='flex-end' spacing={2}>
                    <Grid2>
                        <TextButton sx={{ margin: 0 }} disabled={currentStep === STEPS.PUMP_STEP} onClick={() => setCurrentStep(STEPS.PUMP_STEP)}>{i18n.previous}</TextButton>
                    </Grid2>
                    <Grid2>
                        <MainButton sx={{ margin: 0 }} disabled={buttonDisabled} onClick={() => currentStep < STEPS.COUNTER_STEP ? setCurrentStep(STEPS.COUNTER_STEP) : createMats()}>{currentStep === STEPS.COUNTER_STEP ? i18n.validate : i18n.next}</MainButton>
                    </Grid2>
                </Grid2>
            </DialogActions>
        </DialogMUI>
    )
}

ModalCreateCounter.propTypes = {
    codeInstallation: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    idInstallation: PropTypes.number,
}

export default ModalCreateCounter