import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { Grid2, useTheme } from '@mui/material'
import { LightCard } from '../styled/grid'
import LoadingCard from './LoadingCard'
import { veryLightColor } from '../styled/theme'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoInstallation from '../../referencials/installations/dto/DtoInstallation'

const LocalizationCardDesktop = ({ data, size=12 }) => {
    const {
        citiesIndex,
        cities,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const getSelectedItem = () => {
        const city = citiesIndex[data.townCode]
        return city || {}
    }

    const theme = useTheme()

    if (cities.length) {
        const city = getSelectedItem()
        return (
            <LightCard
                container
                size={size}
                direction='column'
                sx={{
                    padding: theme.spacing(4)
                }}
            >
                <Grid2 fontSize={22} sx={{ fontWeight: 'regular' }}>{i18n.localisation}</Grid2>
                <Grid2 container direction='column' sx={{ paddingTop: theme.spacing(3) }}>
                    <Grid2 container justifyContent='space-between'>
                        <Grid2>{i18n.city}</Grid2>
                        <Grid2 sx={{ fontWeight: 'medium' }}>{city?.name || '-'}</Grid2>
                    </Grid2>
                    <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                        <Grid2>{i18n.lieuDit}</Grid2>
                        <Grid2 sx={{ fontWeight: 'medium' }}>{data?.location || '-'}</Grid2>
                    </Grid2>
                    <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                        <Grid2>{i18n.codeParcelle}</Grid2>
                        <Grid2 sx={{ fontWeight: 'medium' }}>{data?.link_geo?.length ? `${data.link_geo[0].parcel} ${data.link_geo[0].section}` : '-'}</Grid2>
                    </Grid2>
                    <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                        <Grid2>{i18n.xLambert}</Grid2>
                        <Grid2 sx={{ fontWeight: 'medium' }}>{data?.x || '-'}</Grid2>
                    </Grid2>
                    <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                        <Grid2>{i18n.yLambert}</Grid2>
                        <Grid2 sx={{ fontWeight: 'medium' }}>{data?.y || '-'}</Grid2>
                    </Grid2>
                </Grid2>
            </LightCard>
        )
    }
    return <LoadingCard size={2.8} sx={{ backgroundColor: veryLightColor, padding: theme.spacing(4) }}/>
}

LocalizationCardDesktop.propTypes = {
    data: instanceOf(DtoInstallation),
    size: PropTypes.number,
}

export default LocalizationCardDesktop